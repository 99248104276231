import * as React from "react";
import { useNavigate } from "react-router-dom";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Alert from "@mui/material/Alert";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"; // Can be updated to the attached link once available: https://mui.com/x/react-date-pickers/date-time-range-picker/
import { ApplicationNavigation } from "../components/navigation";
import { AuthMiddleWare } from "../utils/authMiddleWare";

import dayjs from "dayjs";
import axios from "axios";

const drawerWidth = 300;
const defaultTheme = createTheme();

const columns = [
  { id: "boxTitle", label: "Name", minWidth: 100 },
  { id: "boxId", label: "ID", minWidth: 10 },
  {
    id: "boxBasestations",
    label: "Basestations",
    minWidth: 170,
  },
  {
    id: "boxRelays",
    label: "Relays",
    minWidth: 220,
  },
  {
    id: "boxWatches",
    label: "Watches",
    minWidth: 200,
  },
  {
    id: "boxHotspots",
    label: "Hotspots",
    minWidth: 200,
  },
  {
    id: "boxStatus",
    label: "Status",
    minWidth: 50,
  },
  {
    id: "boxType",
    label: "Type",
    minWidth: 150,
  },
];
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(7),
      },
    }),
  },
}));

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://besic.org/">
        BESI-C
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Boxes() {
  const navigate = useNavigate();
  AuthMiddleWare(navigate);

  const [boxes, setBoxes] = React.useState({});
  const [userLevel, setUserLevel] = React.useState("");
  const [open, setOpen] = React.useState(true);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [openNewBox, setOpenNewBox] = React.useState(false);
  const [boxAvailable, setBoxAvailable] = React.useState(false);

  const [boxRelayOptionsDeepCopy, setBoxRelayOptionsDeepCopy] = React.useState(
    []
  );
  const [
    boxBasestationOptionsDeepCopy,
    setBoxBasestationOptionsDeepCopy,
  ] = React.useState([]);
  const [boxWatchOptionsDeepCopy, setBoxWatchOptionsDeepCopy] = React.useState(
    []
  );
  const [
    boxHotspotOptionsDeepCopy,
    setBoxHotspotOptionsDeepCopy,
  ] = React.useState([]);
  const [boxRelayOptions, setBoxRelayOptions] = React.useState([]);
  const [boxBasestationOptions, setBoxBasestationOptions] = React.useState([]);
  const [boxWatchOptions, setBoxWatchOptions] = React.useState([]);
  const [boxHotspotOptions, setBoxHotspotOptions] = React.useState([]);
  const [documentId, setDocumentId] = React.useState("");
  const [boxId, setBoxId] = React.useState("");
  const [boxTitle, setBoxTitle] = React.useState("");
  const [boxCreatedDate, setBoxCreatedDate] = React.useState("");
  const [boxRelay, setBoxRelay] = React.useState([]);
  const [boxBasestation, setBoxBasestation] = React.useState([]);
  const [boxWatch, setBoxWatch] = React.useState([]);
  const [boxHotspot, setBoxHotspot] = React.useState([]);
  const [boxStatus, setBoxStatus] = React.useState("");
  const [boxType, setBoxType] = React.useState("");
  const [boxNotes, setBoxNotes] = React.useState("");

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const handleOpen = () => {
    setOpenPopup(true);
  };

  const handleClose = () => {
    setDocumentId("");
    setBoxId("");
    setBoxTitle("");
    setBoxCreatedDate("");
    setBoxStatus("");
    setBoxRelay([]);
    setBoxBasestation([]);
    setBoxWatch([]);
    setBoxHotspot([]);
    setBoxType("");
    setBoxNotes("");

    setBoxRelayOptions(boxRelayOptionsDeepCopy);
    setBoxBasestationOptions(boxBasestationOptionsDeepCopy);
    setBoxWatchOptions(boxWatchOptionsDeepCopy);
    setBoxHotspotOptions(boxHotspotOptionsDeepCopy);

    setOpenPopup(false);
    setOpenNewBox(false);
  };

  const handleNewBox = () => {
    setDocumentId("");
    setBoxCreatedDate(new Date().toISOString());
    setOpenNewBox(true);
  };

  const handleOpenPopup = (event) => {
    AuthMiddleWare(navigate);
    const authToken = localStorage.getItem("AuthToken");
    axios.defaults.headers.common = { Authorization: `${authToken}` };

    let relaysInBox = [];
    let basestationsInBox = [];
    let watchesInBox = [];
    let hotspotsInBox = [];

    setBoxRelayOptions([]);
    setBoxBasestationOptions([]);
    setBoxWatchOptions([]);
    setBoxHotspotOptions([]);

    setBoxRelayOptionsDeepCopy([]);
    setBoxBasestationOptionsDeepCopy([]);
    setBoxWatchOptionsDeepCopy([]);
    setBoxHotspotOptionsDeepCopy([]);

    axios
      .get("/boxes/" + event)
      .then((response) => {
        setDocumentId(response.data.documentId);
        setBoxId(response.data.boxId);
        setBoxTitle(response.data.boxTitle);
        setBoxCreatedDate(response.data.boxCreatedDate);
        setBoxRelay(response.data.boxRelays);
        setBoxWatch(response.data.boxWatches);
        setBoxHotspot(response.data.boxHotspots);
        setBoxBasestation(response.data.boxBasestations);
        setBoxStatus(response.data.boxStatus);
        setBoxType(response.data.boxType);
        setBoxNotes(response.data.boxNotes);

        relaysInBox = response.data.boxRelays;
        basestationsInBox = response.data.boxBasestations;
        watchesInBox = response.data.boxWatches;
        hotspotsInBox = response.data.boxHotspots;

        setBoxAvailable(true);
        if (response.data.boxStatus === "active") {
          setBoxAvailable(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get("/relays")
      .then((response) => {
        let relaysAvailable = [];
        response.data.map((relay) => {
          relaysInBox.map((relayInUseTitle) => {
            if (relay.relayTitle === relayInUseTitle) {
              relaysAvailable.push(relay);
            }
            return relayInUseTitle;
          });
          return relay;
        });

        boxRelayOptions.map((boxRelay) => {
          relaysAvailable.push(boxRelay);
          return boxRelay;
        });
        setBoxRelayOptions(relaysAvailable);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get("/watches")
      .then((response) => {
        let watchesAvailable = [];
        response.data.map((watch) => {
          watchesInBox.map((watchInUseTitle) => {
            if (watch.watchTitle === watchInUseTitle) {
              watchesAvailable.push(watch);
            }
            return watchInUseTitle;
          });
          return watch;
        });

        boxWatchOptions.map((boxWatch) => {
          watchesAvailable.push(boxWatch);
          return boxWatch;
        });
        setBoxWatchOptions(watchesAvailable);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get("/hotspots")
      .then((response) => {
        let hotspotsAvailable = [];
        response.data.map((hotspot) => {
          hotspotsInBox.map((hotspotInUseTitle) => {
            if (hotspot.hotspotTitle === hotspotInUseTitle) {
              hotspotsAvailable.push(hotspot);
            }
            return hotspotInUseTitle;
          });
          return hotspot;
        });

        boxHotspotOptions.map((boxHotspot) => {
          hotspotsAvailable.push(boxHotspot);
          return boxHotspot;
        });
        setBoxHotspotOptions(hotspotsAvailable);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get("/basestations")
      .then((response) => {
        let basestationsAvailable = [];
        response.data.map((basestation) => {
          basestationsInBox.map((basestationInUseTitle) => {
            if (basestation.basestationTitle === basestationInUseTitle) {
              basestationsAvailable.push(basestation);
            }
            return basestationInUseTitle;
          });
          return basestation;
        });

        boxBasestationOptions.map((boxBasestation) => {
          basestationsAvailable.push(boxBasestation);
          return boxBasestation;
        });
        setBoxBasestationOptions(basestationsAvailable);
      })
      .catch((error) => {
        console.log(error);
      });

    handleOpen();
  };

  React.useEffect(() => {
    AuthMiddleWare(navigate);
    const authToken = localStorage.getItem("AuthToken");
    axios.defaults.headers.common = { Authorization: `${authToken}` };

    setBoxRelayOptions([]);
    setBoxBasestationOptions([]);
    setBoxWatchOptions([]);
    setBoxHotspotOptions([]);

    setBoxRelayOptionsDeepCopy([]);
    setBoxBasestationOptionsDeepCopy([]);
    setBoxWatchOptionsDeepCopy([]);
    setBoxHotspotOptionsDeepCopy([]);

    axios
      .get("/boxes")
      .then((response) => {
        setBoxes(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get("/relays")
      .then((response) => {
        let relaysAvailable = [];
        response.data.map((relay) => {
          if (relay.relayStatus === "available") {
            relaysAvailable.push(relay);
          }
          return relay;
        });
        setBoxRelayOptions(relaysAvailable);
        setBoxRelayOptionsDeepCopy(relaysAvailable);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get("/watches")
      .then((response) => {
        let watchesAvailable = [];
        response.data.map((watch) => {
          if (watch.watchStatus === "available") {
            watchesAvailable.push(watch);
          }
          return watch;
        });
        setBoxWatchOptions(watchesAvailable);
        setBoxWatchOptionsDeepCopy(watchesAvailable);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get("/hotspots")
      .then((response) => {
        let hotspotsAvailable = [];
        response.data.map((hotspot) => {
          if (hotspot.hotspotStatus === "available") {
            hotspotsAvailable.push(hotspot);
          }
          return hotspot;
        });
        setBoxHotspotOptions(hotspotsAvailable);
        setBoxHotspotOptionsDeepCopy(hotspotsAvailable);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get("/basestations")
      .then((response) => {
        let basestationsAvailable = [];
        response.data.map((basestation) => {
          if (basestation.basestationStatus === "available") {
            basestationsAvailable.push(basestation);
          }
          return basestation;
        });
        setBoxBasestationOptions(basestationsAvailable);
        setBoxBasestationOptionsDeepCopy(basestationsAvailable);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [navigate]);

  React.useEffect(() => {
    const getUserData = () => {
      AuthMiddleWare(navigate);
      const authToken = localStorage.getItem("AuthToken");
      axios.defaults.headers.common = { Authorization: `${authToken}` };

      axios
        .get("/user")
        .then((response) => {
          setUserLevel(response.data.userCredentials.level);
        })
        .catch((error) => {
          if (error.response.status === 403) {
            navigate("/signin");
          }
        });
    };

    getUserData();
  });

  function TableViewOfData() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [alert, setAlert] = React.useState("");
    const [error, setError] = React.useState("");
    const [edit, setEdit] = React.useState(false);

    const [boxIdToSend, setBoxIdToSend] = React.useState("");
    const [boxIdToUpdate, setBoxIdToUpdate] = React.useState(boxId);
    const [boxTitleToSend, setBoxTitleToSend] = React.useState("");
    const [boxTitleToUpdate, setBoxTitleToUpdate] = React.useState(boxTitle);
    const [boxRelayToSend, setBoxRelayToSend] = React.useState([]);
    const [boxRelayToUpdate, setBoxRelayToUpdate] = React.useState(boxRelay);
    const [boxBasestationToSend, setBoxBasestationToSend] = React.useState([]);
    const [boxBasestationToUpdate, setBoxBasestationToUpdate] = React.useState(
      boxBasestation
    );
    const [boxWatchToSend, setBoxWatchToSend] = React.useState([]);
    const [boxWatchToUpdate, setBoxWatchToUpdate] = React.useState(boxWatch);
    const [boxHotspotToSend, setBoxHotspotToSend] = React.useState([]);
    const [boxHotspotToUpdate, setBoxHotspotToUpdate] = React.useState(
      boxHotspot
    );
    const [boxStatusToSend, setBoxStatusToSend] = React.useState("available");
    const [boxStatusToUpdate, setBoxStatusToUpdate] = React.useState(boxStatus);
    const [boxTypeToSend, setBoxTypeToSend] = React.useState("");
    const [boxTypeToUpdate, setBoxTypeToUpdate] = React.useState(boxType);
    const [boxNotesToSend, setBoxNotesToSend] = React.useState("");
    const [boxNotesToUpdate, setBoxNotesToUpdate] = React.useState(boxNotes);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
    const handleEdit = () => {
      setError("");
      setAlert("");
      setEdit(!edit);
    };
    const handleDelete = () => {
      setError("");
      setAlert("");

      AuthMiddleWare(navigate);
      const authToken = localStorage.getItem("AuthToken");
      axios.defaults.headers.common = { Authorization: `${authToken}` };

      axios
        .delete("/boxes/" + documentId)
        .then((response) => {
          axios
            .get("/relays")
            .then((response) => {
              let relaysToGoAvailable = [];

              response.data.map((relay) => {
                boxRelay.map((currentRelay) => {
                  if (relay.relayTitle === currentRelay) {
                    relaysToGoAvailable.push(relay);
                  }
                  return currentRelay;
                });
                return relay;
              });

              relaysToGoAvailable.map((relay) => {
                const currentRelay = {
                  relayId: relay.relayId,
                  relayTitle: relay.relayTitle,
                  relayMacAddress: relay.relayMacAddress,
                  relayStatus: "available",
                  relayType: relay.relayType,
                  relaySerialNumber: relay.relaySerialNumber,
                  relayNotes: relay.relayNotes,
                };

                axios
                  .put("/relays/" + relay.documentId, currentRelay)
                  .then((response) => {
                    setAlert("Success: " + response.data.message);
                  })
                  .catch((error) => {
                    setError("Error: " + error.response.data.error);
                  });

                return relay;
              });
            })
            .catch((error) => {
              console.log(error);
            });

          axios
            .get("/basestations")
            .then((response) => {
              let basestationsToGoAvailable = [];

              response.data.map((basestation) => {
                boxBasestation.map((currentBasestation) => {
                  if (basestation.basestationTitle === currentBasestation) {
                    basestationsToGoAvailable.push(basestation);
                  }
                  return currentBasestation;
                });
                return basestation;
              });

              basestationsToGoAvailable.map((basestation) => {
                const currentBasestation = {
                  basestationId: basestation.basestationId,
                  basestationTitle: basestation.basestationTitle,
                  basestationMacAddress: basestation.basestationMacAddress,
                  basestationStatus: "available",
                  basestationType: basestation.basestationType,
                  basestationNotes: basestation.basestationNotes,
                };

                axios
                  .put(
                    "/basestations/" + basestation.documentId,
                    currentBasestation
                  )
                  .then((response) => {
                    setAlert("Success: " + response.data.message);
                  })
                  .catch((error) => {
                    setError("Error: " + error.response.data.error);
                  });

                return basestation;
              });
            })
            .catch((error) => {
              console.log(error);
            });

          axios
            .get("/watches")
            .then((response) => {
              let watchesToGoAvailable = [];

              response.data.map((watch) => {
                boxWatch.map((currentWatch) => {
                  if (watch.watchTitle === currentWatch) {
                    watchesToGoAvailable.push(watch);
                  }
                  return currentWatch;
                });
                return watch;
              });

              watchesToGoAvailable.map((watch) => {
                const currentWatch = {
                  watchId: watch.watchId,
                  watchTitle: watch.watchTitle,
                  watchStatus: "available",
                  watchType: watch.watchType,
                  watchMacAddress: watch.watchMacAddress,
                  watchSerialNumber: watch.watchSerialNumber,
                  watchBrand: watch.watchBrand,
                  watchColor: watch.watchColor,
                  watchRole: watch.watchRole,
                  watchNotes: watch.watchNotes,
                };

                axios
                  .put("/watches/" + watch.documentId, currentWatch)
                  .then((response) => {
                    setAlert("Success: " + response.data.message);
                  })
                  .catch((error) => {
                    setError("Error: " + error.response.data.error);
                  });

                return watch;
              });
            })
            .catch((error) => {
              console.log(error);
            });

          axios
            .get("/hotspots")
            .then((response) => {
              let hotspotsToGoAvailable = [];

              response.data.map((hotspot) => {
                boxHotspot.map((currentHotspot) => {
                  if (hotspot.hotspotTitle === currentHotspot) {
                    hotspotsToGoAvailable.push(hotspot);
                  }
                  return currentHotspot;
                });
                return hotspot;
              });

              hotspotsToGoAvailable.map((hotspot) => {
                const currentHotspot = {
                  hotspotId: hotspot.hotspotId,
                  hotspotTitle: hotspot.hotspotTitle,
                  hotspotStatus: "available",
                  hotspotType: hotspot.hotspotType,
                  hotspotMacAddress: hotspot.hotspotMacAddress,
                  hotspotNotes: hotspot.hotspotNotes,
                  hotspotImei: hotspot.hotspotImei,
                  hotspotAccountNumber: hotspot.hotspotAccountNumber,
                  hotspotCarrier: hotspot.hotspotCarrier,
                };

                axios
                  .put("/hotspots/" + hotspot.documentId, currentHotspot)
                  .then((response) => {
                    setAlert("Success: " + response.data.message);
                  })
                  .catch((error) => {
                    setError("Error: " + error.response.data.error);
                  });

                return hotspot;
              });
            })
            .catch((error) => {
              console.log(error);
            });

          setAlert("Success: " + response.data.message);
          window.location.reload();
        })
        .catch((error) => {
          setError("Error: " + error.response.data.error);
        });
    };

    const onBoxIdToSendChangeHandler = (inputText) => {
      setBoxIdToSend(inputText.target.value);
    };
    const onBoxIdToUpdateChangeHandler = (inputText) => {
      setBoxIdToUpdate(inputText.target.value);
    };
    const onBoxRelayToSendChangeHandler = (inputText) => {
      const {
        target: { value },
      } = inputText;
      setBoxRelayToSend(typeof value === "string" ? value.split(",") : value);
    };
    const onBoxRelayToUpdateChangeHandler = (inputText) => {
      const {
        target: { value },
      } = inputText;
      setBoxRelayToUpdate(typeof value === "string" ? value.split(",") : value);
    };
    const onBoxBasestationToSendChangeHandler = (inputText) => {
      const {
        target: { value },
      } = inputText;
      setBoxBasestationToSend(
        typeof value === "string" ? value.split(",") : value
      );
    };
    const onBoxBasestationToUpdateChangeHandler = (inputText) => {
      const {
        target: { value },
      } = inputText;
      setBoxBasestationToUpdate(
        typeof value === "string" ? value.split(",") : value
      );
    };
    const onBoxWatchToSendChangeHandler = (inputText) => {
      const {
        target: { value },
      } = inputText;
      setBoxWatchToSend(typeof value === "string" ? value.split(",") : value);
    };
    const onBoxWatchToUpdateChangeHandler = (inputText) => {
      const {
        target: { value },
      } = inputText;
      setBoxWatchToUpdate(typeof value === "string" ? value.split(",") : value);
    };
    const onBoxHotspotToSendChangeHandler = (inputText) => {
      const {
        target: { value },
      } = inputText;
      setBoxHotspotToSend(typeof value === "string" ? value.split(",") : value);
    };
    const onBoxHotspotToUpdateChangeHandler = (inputText) => {
      const {
        target: { value },
      } = inputText;
      setBoxHotspotToUpdate(
        typeof value === "string" ? value.split(",") : value
      );
    };
    const onBoxTitleToSendChangeHandler = (inputText) => {
      setBoxTitleToSend(inputText.target.value);
    };
    const onBoxTitleToUpdateChangeHandler = (inputText) => {
      setBoxTitleToUpdate(inputText.target.value);
    };
    const onBoxStatusToSendChangeHandler = (inputText) => {
      setBoxStatusToSend(inputText.target.value);
    };
    const onBoxStatusToUpdateChangeHandler = (inputText) => {
      setBoxStatusToUpdate(inputText.target.value);
    };
    const onBoxTypeToSendChangeHandler = (inputText) => {
      setBoxTypeToSend(inputText.target.value);
    };
    const onBoxTypeToUpdateChangeHandler = (inputText) => {
      setBoxTypeToUpdate(inputText.target.value);
    };
    const onBoxNotesToSendChangeHandler = (inputText) => {
      setBoxNotesToSend(inputText.target.value);
    };
    const onBoxNotesToUpdateChangeHandler = (inputText) => {
      setBoxNotesToUpdate(inputText.target.value);
    };

    const handleSave = () => {
      AuthMiddleWare(navigate);
      const authToken = localStorage.getItem("AuthToken");
      axios.defaults.headers.common = { Authorization: `${authToken}` };

      setError("");
      setAlert("");

      const newBox = {
        boxId: boxIdToSend,
        boxTitle: boxTitleToSend,
        boxStatus: boxStatusToSend,
        boxType: boxTypeToSend,
        boxRelays: boxRelayToSend,
        boxBasestations: boxBasestationToSend,
        boxWatches: boxWatchToSend,
        boxHotspots: boxHotspotToSend,
        boxNotes: boxNotesToSend,
      };

      axios
        .get("/relays")
        .then((response) => {
          let relaysToGoActive = [];

          response.data.map((relay) => {
            boxRelayToSend.map((currentRelay) => {
              if (relay.relayTitle === currentRelay) {
                relaysToGoActive.push(relay);
              }
              return currentRelay;
            });
            return relay;
          });

          relaysToGoActive.map((relay) => {
            const currentRelay = {
              relayId: relay.relayId,
              relayTitle: relay.relayTitle,
              relayMacAddress: relay.relayMacAddress,
              relayStatus: "active",
              relayType: relay.relayType,
              relaySerialNumber: relay.relaySerialNumber,
              relayNotes: relay.relayNotes,
            };

            axios
              .put("/relays/" + relay.documentId, currentRelay)
              .then((response) => {
                setAlert("Success: " + response.data.message);
              })
              .catch((error) => {
                setError("Error: " + error.response.data.error);
              });

            return relay;
          });
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .get("/watches")
        .then((response) => {
          let watchesToGoActive = [];

          response.data.map((watch) => {
            boxWatchToSend.map((currentWatch) => {
              if (watch.watchTitle === currentWatch) {
                watchesToGoActive.push(watch);
              }
              return currentWatch;
            });
            return watch;
          });

          watchesToGoActive.map((watch) => {
            const currentWatch = {
              watchId: watch.watchId,
              watchTitle: watch.watchTitle,
              watchStatus: "active",
              watchType: watch.watchType,
              watchMacAddress: watch.watchMacAddress,
              watchSerialNumber: watch.watchSerialNumber,
              watchBrand: watch.watchBrand,
              watchColor: watch.watchColor,
              watchRole: watch.watchRole,
              watchNotes: watch.watchNotes,
            };

            axios
              .put("/watches/" + watch.documentId, currentWatch)
              .then((response) => {
                setAlert("Success: " + response.data.message);
              })
              .catch((error) => {
                setError("Error: " + error.response.data.error);
              });

            return watch;
          });
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .get("/hotspots")
        .then((response) => {
          let hotspotsToGoActive = [];

          response.data.map((hotspot) => {
            boxHotspotToSend.map((currentHotspot) => {
              if (hotspot.hotspotTitle === currentHotspot) {
                hotspotsToGoActive.push(hotspot);
              }
              return currentHotspot;
            });
            return hotspot;
          });

          hotspotsToGoActive.map((hotspot) => {
            const currentHotspot = {
              hotspotId: hotspot.hotspotId,
              hotspotTitle: hotspot.hotspotTitle,
              hotspotStatus: "active",
              hotspotType: hotspot.hotspotType,
              hotspotMacAddress: hotspot.hotspotMacAddress,
              hotspotNotes: hotspot.hotspotNotes,
              hotspotImei: hotspot.hotspotImei,
              hotspotAccountNumber: hotspot.hotspotAccountNumber,
              hotspotCarrier: hotspot.hotspotCarrier,
            };

            axios
              .put("/hotspots/" + hotspot.documentId, currentHotspot)
              .then((response) => {
                setAlert("Success: " + response.data.message);
              })
              .catch((error) => {
                setError("Error: " + error.response.data.error);
              });

            return hotspot;
          });
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .get("/basestations")
        .then((response) => {
          let basestationsToGoActive = [];

          response.data.map((basestation) => {
            boxBasestationToSend.map((currentBasestation) => {
              if (basestation.basestationTitle === currentBasestation) {
                basestationsToGoActive.push(basestation);
              }
              return currentBasestation;
            });
            return basestation;
          });

          basestationsToGoActive.map((basestation) => {
            const currentBasestation = {
              basestationId: basestation.basestationId,
              basestationTitle: basestation.basestationTitle,
              basestationMacAddress: basestation.basestationMacAddress,
              basestationStatus: "active",
              basestationType: basestation.basestationType,
              basestationNotes: basestation.basestationNotes,
            };

            axios
              .put(
                "/basestations/" + basestation.documentId,
                currentBasestation
              )
              .then((response) => {
                setAlert("Success: " + response.data.message);
              })
              .catch((error) => {
                setError("Error: " + error.response.data.error);
              });

            return basestation;
          });
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .post("/boxes", newBox)
        .then((response) => {
          setBoxIdToSend("");
          setBoxTitleToSend("");
          setBoxStatusToSend("");
          setBoxTypeToSend("");
          setBoxRelayToSend([]);
          setBoxBasestationToSend([]);
          setBoxWatchToSend([]);
          setBoxHotspotToSend([]);
          setBoxNotesToSend("");

          setAlert(
            "Box " + response.data.documentId + " successfully created!"
          );
          window.location.reload();
        })
        .catch((error) => {
          setError("Error: " + error.response.data.error);
        });
    };

    const handleUpdate = () => {
      AuthMiddleWare(navigate);
      const authToken = localStorage.getItem("AuthToken");
      axios.defaults.headers.common = { Authorization: `${authToken}` };

      const currentBox = {
        boxId: boxId,
        boxTitle: boxTitle,
        boxStatus: boxStatus,
        boxRelays: boxRelay,
        boxBasestations: boxBasestation,
        boxWatches: boxWatch,
        boxHotspots: boxHotspot,
        boxType: boxType,
        boxNotes: boxNotes,
      };

      const updateBox = {
        boxId: boxIdToUpdate,
        boxTitle: boxTitleToUpdate,
        boxRelays: boxRelayToUpdate,
        boxBasestations: boxBasestationToUpdate,
        boxWatches: boxWatchToUpdate,
        boxHotspots: boxHotspotToUpdate,
        boxStatus: boxStatusToUpdate,
        boxType: boxTypeToUpdate,
        boxNotes: boxNotesToUpdate,
      };

      if (
        currentBox.boxId === updateBox.boxId &&
        currentBox.boxTitle === updateBox.boxTitle &&
        currentBox.boxStatus === updateBox.boxStatus &&
        currentBox.boxType === updateBox.boxType &&
        currentBox.boxNotes === updateBox.boxNotes &&
        currentBox.boxRelays === updateBox.boxRelays &&
        currentBox.boxBasestations === updateBox.boxBasestations &&
        currentBox.boxWatches === updateBox.boxWatches &&
        currentBox.boxHotspots === updateBox.boxHotspots
      ) {
        setError("Error: Please make a change before updating the box");
      } else {
        axios
          .get("/relays")
          .then((response) => {
            let relaysToGoAvailable = [];
            let relaysToGoActive = [];

            response.data.map((relay) => {
              boxRelay.map((currentRelay) => {
                if (relay.relayTitle === currentRelay) {
                  relaysToGoAvailable.push(relay);
                }
                return currentRelay;
              });

              boxRelayToUpdate.map((updateRelay) => {
                if (relay.relayTitle === updateRelay) {
                  relaysToGoActive.push(relay);
                }
                return updateRelay;
              });

              return relay;
            });

            relaysToGoAvailable.map((relay) => {
              const currentRelay = {
                relayId: relay.relayId,
                relayTitle: relay.relayTitle,
                relayMacAddress: relay.relayMacAddress,
                relayStatus: "available",
                relayType: relay.relayType,
                relaySerialNumber: relay.relaySerialNumber,
                relayNotes: relay.relayNotes,
              };

              if (!relaysToGoActive.includes(relay)) {
                axios
                  .put("/relays/" + relay.documentId, currentRelay)
                  .then((response) => {
                    setAlert("Success: " + response.data.message);
                  })
                  .catch((error) => {
                    setError("Error: " + error.response.data.error);
                  });
              }

              return relay;
            });

            relaysToGoActive.map((relay) => {
              const updateRelay = {
                relayId: relay.relayId,
                relayTitle: relay.relayTitle,
                relayMacAddress: relay.relayMacAddress,
                relaySerialNumber: relay.relaySerialNumber,
                relayStatus: "active",
                relayType: relay.relayType,
                relayNotes: relay.relayNotes,
              };

              axios
                .put("/relays/" + relay.documentId, updateRelay)
                .then((response) => {
                  setAlert("Success: " + response.data.message);
                })
                .catch((error) => {
                  setError("Error: " + error.response.data.error);
                });

              return relay;
            });
          })
          .catch((error) => {
            console.log(error);
          });

        axios
          .get("/watches")
          .then((response) => {
            let watchesToGoAvailable = [];
            let watchesToGoActive = [];

            response.data.map((watch) => {
              boxWatch.map((currentWatch) => {
                if (watch.watchTitle === currentWatch) {
                  watchesToGoAvailable.push(watch);
                }
                return currentWatch;
              });

              boxWatchToUpdate.map((updateWatch) => {
                if (watch.watchTitle === updateWatch) {
                  watchesToGoActive.push(watch);
                }
                return updateWatch;
              });

              return watch;
            });

            watchesToGoAvailable.map((watch) => {
              const currentWatch = {
                watchId: watch.watchId,
                watchTitle: watch.watchTitle,
                watchStatus: "available",
                watchType: watch.watchType,
                watchMacAddress: watch.watchMacAddress,
                watchSerialNumber: watch.watchSerialNumber,
                watchBrand: watch.watchBrand,
                watchColor: watch.watchColor,
                watchRole: watch.watchRole,
                watchNotes: watch.watchNotes,
              };

              if (!watchesToGoActive.includes(watch)) {
                axios
                  .put("/watches/" + watch.documentId, currentWatch)
                  .then((response) => {
                    setAlert("Success: " + response.data.message);
                  })
                  .catch((error) => {
                    setError("Error: " + error.response.data.error);
                  });
              }

              return watch;
            });

            watchesToGoActive.map((watch) => {
              const updateWatch = {
                watchId: watch.watchId,
                watchTitle: watch.watchTitle,
                watchStatus: "active",
                watchType: watch.watchType,
                watchMacAddress: watch.watchMacAddress,
                watchSerialNumber: watch.watchSerialNumber,
                watchBrand: watch.watchBrand,
                watchColor: watch.watchColor,
                watchRole: watch.watchRole,
                watchNotes: watch.watchNotes,
              };

              axios
                .put("/watches/" + watch.documentId, updateWatch)
                .then((response) => {
                  setAlert("Success: " + response.data.message);
                })
                .catch((error) => {
                  setError("Error: " + error.response.data.error);
                });

              return watch;
            });
          })
          .catch((error) => {
            console.log(error);
          });

        axios
          .get("/hotspots")
          .then((response) => {
            let hotspotsToGoAvailable = [];
            let hotspotsToGoActive = [];

            response.data.map((hotspot) => {
              boxHotspot.map((currentHotspot) => {
                if (hotspot.hotspotTitle === currentHotspot) {
                  hotspotsToGoAvailable.push(hotspot);
                }
                return currentHotspot;
              });

              boxHotspotToUpdate.map((updateHotspot) => {
                if (hotspot.hotspotTitle === updateHotspot) {
                  hotspotsToGoActive.push(hotspot);
                }
                return updateHotspot;
              });

              return hotspot;
            });

            hotspotsToGoAvailable.map((hotspot) => {
              const currentHotspot = {
                hotspotId: hotspot.hotspotId,
                hotspotTitle: hotspot.hotspotTitle,
                hotspotStatus: "available",
                hotspotType: hotspot.hotspotType,
                hotspotMacAddress: hotspot.hotspotMacAddress,
                hotspotNotes: hotspot.hotspotNotes,
                hotspotImei: hotspot.hotspotImei,
                hotspotAccountNumber: hotspot.hotspotAccountNumber,
                hotspotCarrier: hotspot.hotspotCarrier,
              };

              if (!hotspotsToGoActive.includes(hotspot)) {
                axios
                  .put("/hotspots/" + hotspot.documentId, currentHotspot)
                  .then((response) => {
                    setAlert("Success: " + response.data.message);
                  })
                  .catch((error) => {
                    setError("Error: " + error.response.data.error);
                  });
              }

              return hotspot;
            });

            hotspotsToGoActive.map((hotspot) => {
              const updateHotspot = {
                hotspotId: hotspot.hotspotId,
                hotspotTitle: hotspot.hotspotTitle,
                hotspotStatus: "active",
                hotspotType: hotspot.hotspotType,
                hotspotMacAddress: hotspot.hotspotMacAddress,
                hotspotNotes: hotspot.hotspotNotes,
                hotspotImei: hotspot.hotspotImei,
                hotspotAccountNumber: hotspot.hotspotAccountNumber,
                hotspotCarrier: hotspot.hotspotCarrier,
              };

              axios
                .put("/hotspots/" + hotspot.documentId, updateHotspot)
                .then((response) => {
                  setAlert("Success: " + response.data.message);
                })
                .catch((error) => {
                  setError("Error: " + error.response.data.error);
                });

              return hotspot;
            });
          })
          .catch((error) => {
            console.log(error);
          });

        axios
          .get("/basestations")
          .then((response) => {
            let basestationsToGoAvailable = [];
            let basestationsToGoActive = [];

            response.data.map((basestation) => {
              boxBasestation.map((currentBasestation) => {
                if (basestation.basestationTitle === currentBasestation) {
                  basestationsToGoAvailable.push(basestation);
                }
                return currentBasestation;
              });

              boxBasestationToUpdate.map((updateBasestation) => {
                if (basestation.basestationTitle === updateBasestation) {
                  basestationsToGoActive.push(basestation);
                }
                return updateBasestation;
              });

              return basestation;
            });

            basestationsToGoAvailable.map((basestation) => {
              const currentBasestation = {
                basestationId: basestation.basestationId,
                basestationTitle: basestation.basestationTitle,
                basestationMacAddress: basestation.basestationMacAddress,
                basestationStatus: "available",
                basestationType: basestation.basestationType,
                basestationNotes: basestation.basestationNotes,
              };

              if (!basestationsToGoActive.includes(basestation)) {
                axios
                  .put(
                    "/basestations/" + basestation.documentId,
                    currentBasestation
                  )
                  .then((response) => {
                    setAlert("Success: " + response.data.message);
                  })
                  .catch((error) => {
                    setError("Error: " + error.response.data.error);
                  });
              }

              return basestation;
            });

            basestationsToGoActive.map((basestation) => {
              const updateBasestation = {
                basestationId: basestation.basestationId,
                basestationTitle: basestation.basestationTitle,
                basestationMacAddress: basestation.basestationMacAddress,
                basestationStatus: "active",
                basestationType: basestation.basestationType,
                basestationNotes: basestation.basestationNotes,
              };

              axios
                .put(
                  "/basestations/" + basestation.documentId,
                  updateBasestation
                )
                .then((response) => {
                  setAlert("Success: " + response.data.message);
                })
                .catch((error) => {
                  setError("Error: " + error.response.data.error);
                });

              return basestation;
            });
          })
          .catch((error) => {
            console.log(error);
          });

        axios
          .put("/boxes/" + documentId, updateBox)
          .then((response) => {
            setAlert("Success: " + response.data.message);
            window.location.reload();
          })
          .catch((error) => {
            setError("Error: " + error.response.data.error);
          });
      }

      setEdit(!edit);
    };

    return (
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      width: column.minWidth,
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* eslint-disable-next-line */}
              {new Array(boxes).map((allBoxes) => {
                if (allBoxes.length !== undefined) {
                  return (
                    <React.Fragment key={Math.random()}>
                      {allBoxes
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((box) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={box.documentId}
                              onClick={() => handleOpenPopup(box.documentId)}
                            >
                              {columns.map((column) => {
                                const value = box[column.id];
                                return (
                                  <TableCell key={column.id}>
                                    {value + " "}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </React.Fragment>
                  );
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[1, 5, 10, 20, 30, 50, 100]}
          component="div"
          count={boxes.length !== undefined ? boxes.length : 1}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={openPopup || openNewBox}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Box Actions
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Grid container spacing={1} align="center" justify="center">
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                margin="normal"
                paddingTop={1}
                paddingBottom={1}
                spacing={2}
              ></Grid>
              <Divider variant="middle" />
              <Grid
                container
                paddingRight={5}
                paddingLeft={5}
                paddingTop={1}
                paddingBottom={10}
                spacing={2}
              >
                <Grid
                  container
                  paddingRight={5}
                  paddingLeft={5}
                  paddingTop={3}
                  paddingBottom={10}
                  spacing={2}
                >
                  <Grid item xs={12}>
                    {alert !== "" && (
                      <Alert margin="normal" severity="success">
                        {alert}
                      </Alert>
                    )}
                    {error !== "" && (
                      <Alert margin="normal" severity="error">
                        {error}
                      </Alert>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="boxId"
                      fullWidth
                      margin="normal"
                      id="boxId"
                      helperText="Box ID"
                      type="number"
                      label={boxId !== "" ? boxIdToUpdate : boxIdToSend}
                      autoFocus
                      onChange={
                        documentId !== ""
                          ? onBoxIdToUpdateChangeHandler
                          : onBoxIdToSendChangeHandler
                      }
                      disabled={!edit && !openNewBox}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      margin="normal"
                      id="boxTitle"
                      helperText="Box Name"
                      label={
                        boxTitle !== "" ? boxTitleToUpdate : boxTitleToSend
                      }
                      name="boxTitle"
                      autoFocus
                      onChange={
                        documentId !== ""
                          ? onBoxTitleToUpdateChangeHandler
                          : onBoxTitleToSendChangeHandler
                      }
                      disabled={!edit && !openNewBox}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="documentId"
                      fullWidth
                      margin="normal"
                      id="documentId"
                      helperText="Box Unique ID"
                      label={documentId}
                      autoFocus
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        label="Created Date"
                        defaultValue={dayjs(boxCreatedDate)}
                        disabled
                      />
                    </LocalizationProvider>
                  </Grid>
                  {documentId !== "" ? (
                    <>
                      <Grid item xs={12}>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                          <Select
                            value={boxBasestationToUpdate}
                            onChange={onBoxBasestationToUpdateChangeHandler}
                            displayEmpty
                            multiple
                            fullWidth
                            disabled={!edit && !openNewBox}
                            renderValue={(selected) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {selected.map((value) => (
                                  <Chip key={value} label={value} />
                                ))}
                              </Box>
                            )}
                            inputProps={{ "aria-label": "Basestations" }}
                          >
                            {boxBasestationOptions.map((basestationItem) => (
                              <MenuItem
                                key={basestationItem.basestationTitle}
                                value={basestationItem.basestationTitle}
                              >
                                <Checkbox
                                  checked={
                                    boxBasestationToUpdate.indexOf(
                                      basestationItem.basestationTitle
                                    ) > -1
                                  }
                                />
                                <ListItemText
                                  primary={basestationItem.basestationTitle}
                                />
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>Basestations</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                          <Select
                            value={boxRelayToUpdate}
                            onChange={onBoxRelayToUpdateChangeHandler}
                            displayEmpty
                            multiple
                            fullWidth
                            disabled={!edit && !openNewBox}
                            renderValue={(selected) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {selected.map((value) => (
                                  <Chip key={value} label={value} />
                                ))}
                              </Box>
                            )}
                            inputProps={{ "aria-label": "Relays" }}
                          >
                            {boxRelayOptions.map((relayItem) => (
                              <MenuItem
                                key={relayItem.relayTitle}
                                value={relayItem.relayTitle}
                              >
                                <Checkbox
                                  checked={
                                    boxRelayToUpdate.indexOf(
                                      relayItem.relayTitle
                                    ) > -1
                                  }
                                />
                                <ListItemText primary={relayItem.relayTitle} />
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>Relays</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                          <Select
                            value={boxWatchToUpdate}
                            onChange={onBoxWatchToUpdateChangeHandler}
                            displayEmpty
                            multiple
                            fullWidth
                            disabled={!edit && !openNewBox}
                            renderValue={(selected) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {selected.map((value) => (
                                  <Chip key={value} label={value} />
                                ))}
                              </Box>
                            )}
                            inputProps={{ "aria-label": "Watches" }}
                          >
                            {boxWatchOptions.map((watchItem) => (
                              <MenuItem
                                key={watchItem.watchTitle}
                                value={watchItem.watchTitle}
                              >
                                <Checkbox
                                  checked={
                                    boxWatchToUpdate.indexOf(
                                      watchItem.watchTitle
                                    ) > -1
                                  }
                                />
                                <ListItemText primary={watchItem.watchTitle} />
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>Watches</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                          <Select
                            value={boxHotspotToUpdate}
                            onChange={onBoxHotspotToUpdateChangeHandler}
                            displayEmpty
                            multiple
                            fullWidth
                            disabled={!edit && !openNewBox}
                            renderValue={(selected) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {selected.map((value) => (
                                  <Chip key={value} label={value} />
                                ))}
                              </Box>
                            )}
                            inputProps={{ "aria-label": "Hotspots" }}
                          >
                            {boxHotspotOptions.map((hotspotItem) => (
                              <MenuItem
                                key={hotspotItem.hotspotTitle}
                                value={hotspotItem.hotspotTitle}
                              >
                                <Checkbox
                                  checked={
                                    boxHotspotToUpdate.indexOf(
                                      hotspotItem.hotspotTitle
                                    ) > -1
                                  }
                                />
                                <ListItemText
                                  primary={hotspotItem.hotspotTitle}
                                />
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>Hotspots</FormHelperText>
                        </FormControl>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12}>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                          <Select
                            value={boxBasestationToSend}
                            onChange={onBoxBasestationToSendChangeHandler}
                            displayEmpty
                            multiple
                            fullWidth
                            disabled={!edit && !openNewBox}
                            renderValue={(selected) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {selected.map((value) => (
                                  <Chip key={value} label={value} />
                                ))}
                              </Box>
                            )}
                            inputProps={{ "aria-label": "Basestations" }}
                          >
                            {boxBasestationOptions.map((basestationItem) => (
                              <MenuItem
                                key={basestationItem.basestationTitle}
                                value={basestationItem.basestationTitle}
                              >
                                <Checkbox
                                  checked={
                                    boxBasestationToSend.indexOf(
                                      basestationItem.basestationTitle
                                    ) > -1
                                  }
                                />
                                <ListItemText
                                  primary={basestationItem.basestationTitle}
                                />
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>Basestations</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                          <Select
                            value={boxRelayToSend}
                            onChange={onBoxRelayToSendChangeHandler}
                            displayEmpty
                            multiple
                            fullWidth
                            disabled={!edit && !openNewBox}
                            renderValue={(selected) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {selected.map((value) => (
                                  <Chip key={value} label={value} />
                                ))}
                              </Box>
                            )}
                            inputProps={{ "aria-label": "Relays" }}
                          >
                            {boxRelayOptions.map((relayItem) => (
                              <MenuItem
                                key={relayItem.relayTitle}
                                value={relayItem.relayTitle}
                              >
                                <Checkbox
                                  checked={
                                    boxRelayToSend.indexOf(
                                      relayItem.relayTitle
                                    ) > -1
                                  }
                                />
                                <ListItemText primary={relayItem.relayTitle} />
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>Relays</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                          <Select
                            value={boxWatchToSend}
                            onChange={onBoxWatchToSendChangeHandler}
                            displayEmpty
                            multiple
                            fullWidth
                            disabled={!edit && !openNewBox}
                            renderValue={(selected) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {selected.map((value) => (
                                  <Chip key={value} label={value} />
                                ))}
                              </Box>
                            )}
                            inputProps={{ "aria-label": "Watches" }}
                          >
                            {boxWatchOptions.map((watchItem) => (
                              <MenuItem
                                key={watchItem.watchTitle}
                                value={watchItem.watchTitle}
                              >
                                <Checkbox
                                  checked={
                                    boxWatchToSend.indexOf(
                                      watchItem.watchTitle
                                    ) > -1
                                  }
                                />
                                <ListItemText primary={watchItem.watchTitle} />
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>Watches</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                          <Select
                            value={boxHotspotToSend}
                            onChange={onBoxHotspotToSendChangeHandler}
                            displayEmpty
                            multiple
                            fullWidth
                            disabled={!edit && !openNewBox}
                            renderValue={(selected) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {selected.map((value) => (
                                  <Chip key={value} label={value} />
                                ))}
                              </Box>
                            )}
                            inputProps={{ "aria-label": "Relays" }}
                          >
                            {boxHotspotOptions.map((hotspotItem) => (
                              <MenuItem
                                key={hotspotItem.hotspotTitle}
                                value={hotspotItem.hotspotTitle}
                              >
                                <Checkbox
                                  checked={
                                    boxHotspotToSend.indexOf(
                                      hotspotItem.hotspotTitle
                                    ) > -1
                                  }
                                />
                                <ListItemText
                                  primary={hotspotItem.hotspotTitle}
                                />
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>Hotspots</FormHelperText>
                        </FormControl>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} sm={6}>
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <Select
                        value={boxType !== "" ? boxTypeToUpdate : boxTypeToSend}
                        onChange={
                          documentId !== ""
                            ? onBoxTypeToUpdateChangeHandler
                            : onBoxTypeToSendChangeHandler
                        }
                        displayEmpty
                        disabled={!edit && !openNewBox}
                        inputProps={{ "aria-label": "Type" }}
                      >
                        <MenuItem value="testing">
                          <em>Testing</em>
                        </MenuItem>
                        <MenuItem value="not tested">
                          <em>Not Tested</em>
                        </MenuItem>
                        <MenuItem value="verified">
                          <em>Verified</em>
                        </MenuItem>
                      </Select>
                      <FormHelperText>Type</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <Select
                        value={
                          boxStatus !== "" ? boxStatusToUpdate : boxStatusToSend
                        }
                        onChange={
                          documentId !== ""
                            ? onBoxStatusToUpdateChangeHandler
                            : onBoxStatusToSendChangeHandler
                        }
                        displayEmpty
                        disabled={(!edit && !openNewBox) || !boxAvailable}
                        inputProps={{ "aria-label": "Status" }}
                      >
                        <MenuItem value="unknown">
                          <em>Unknown</em>
                        </MenuItem>
                        <MenuItem value="available">
                          <em>Available</em>
                        </MenuItem>
                        <MenuItem value="broken">
                          <em>Broken</em>
                        </MenuItem>
                        <MenuItem value="retired">
                          <em>Retired</em>
                        </MenuItem>
                        {!boxAvailable ? (
                          <MenuItem value="active">
                            <em>Active</em>
                          </MenuItem>
                        ) : (
                          <></>
                        )}
                      </Select>
                      <FormHelperText>Status</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="notes"
                      fullWidth
                      margin="normal"
                      id="notes"
                      helperText="Notes"
                      label={
                        boxNotes !== "" ? boxNotesToUpdate : boxNotesToSend
                      }
                      onChange={
                        documentId !== ""
                          ? onBoxNotesToUpdateChangeHandler
                          : onBoxNotesToSendChangeHandler
                      }
                      autoFocus
                      multiline
                      disabled={!edit && !openNewBox}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {openNewBox === false && userLevel === "admin" ? (
              <Button autoFocus onClick={handleDelete}>
                Delete
              </Button>
            ) : (
              <></>
            )}

            {openNewBox === false &&
            (userLevel === "admin" || userLevel === "write") ? (
              edit === true ? (
                <Button autoFocus onClick={handleEdit}>
                  Preview
                </Button>
              ) : (
                <Button autoFocus onClick={handleEdit}>
                  Edit
                </Button>
              )
            ) : (
              <></>
            )}
            {documentId !== "" &&
            (userLevel === "admin" || userLevel === "write") ? (
              <Button autoFocus onClick={handleUpdate}>
                Update
              </Button>
            ) : (
              <></>
            )}
            {documentId === "" &&
            (userLevel === "admin" || userLevel === "write") ? (
              <Button autoFocus onClick={handleSave}>
                Save
              </Button>
            ) : (
              <></>
            )}
          </DialogActions>
        </BootstrapDialog>
      </Paper>
    );
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px",
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Boxes
            </Typography>
            {userLevel !== "read" ? (
              <Typography
                component="h2"
                variant="h6"
                color="inherit"
                noWrap
                onClick={handleNewBox}
              >
                Create Box
              </Typography>
            ) : (
              <></>
            )}
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <ApplicationNavigation />
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid
              container
              spacing={1}
              alignItems="center"
              justifyContent="center"
            >
              <Card align="center" justify="center">
                <Box
                  component="form"
                  noValidate
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid
                    container
                    padding={5}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <TableViewOfData />
                  </Grid>
                </Box>
              </Card>
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
