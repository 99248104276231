import * as React from "react";
import { useNavigate } from "react-router-dom";
import FormData from "form-data";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Avatar from "@mui/material/Avatar";
import Alert from "@mui/material/Alert";
import { ApplicationNavigation } from "../components/navigation";
import { AuthMiddleWare } from "../utils/authMiddleWare";

import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_FUNCTIONS_URL;

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://besic.org/">
        BESI-C
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 300;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(7),
      },
    }),
  },
}));

const defaultTheme = createTheme();

export default function Profile() {
  const navigate = useNavigate();

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [userName, setUserName] = React.useState("");
  const [affiliation, setAffiliation] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [userId, setUserId] = React.useState("");
  const [level, setLevel] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState();
  const [image, setImage] = React.useState();
  const [imageUrl, setImageUrl] = React.useState("");
  const [updateImage, setUpdateImage] = React.useState(false);
  const [error, setError] = React.useState("");
  const [open, setOpen] = React.useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const onImageChangeHandler = (inputText) => {
    setUpdateImage(true);
    setImage(inputText.target.files[0]);
  };
  const onFirstNameChangeHandler = (inputText) => {
    setFirstName(inputText.target.value);
  };
  const onLastNameChangeHandler = (inputText) => {
    setLastName(inputText.target.value);
  };
  const onAffiliationChangeHandler = (inputText) => {
    setAffiliation(inputText.target.value);
  };
  const onPhoneNumberChangeHandler = (inputText) => {
    setPhoneNumber(inputText.target.value);
  };
  const onCountryChangeHandler = (inputText) => {
    setCountry(inputText.target.value);
  };

  React.useEffect(() => {
    const getUserData = () => {
      AuthMiddleWare(navigate);
      const authToken = localStorage.getItem("AuthToken");
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      axios
        .get("/user")
        .then((response) => {
          setFirstName(response.data.userCredentials.firstName);
          setLastName(response.data.userCredentials.lastName);
          setUserName(response.data.userCredentials.username);
          setEmail(response.data.userCredentials.email);
          setUserId(response.data.userCredentials.userId);
          setAffiliation(response.data.userCredentials.affiliation);
          setLevel(response.data.userCredentials.level);
          setCountry(response.data.userCredentials.country);
          setPhoneNumber(response.data.userCredentials.phoneNumber);
          setImageUrl(response.data.userCredentials.imageUrl);
        })
        .catch((error) => {
          if (error.response.status === 403) {
            navigate("/signin");
          }
        });
    };

    getUserData();
  }, [navigate]);

  const updateProfilePhoto = async () => {
    AuthMiddleWare(navigate);
    const authToken = localStorage.getItem("AuthToken");
    let formData = new FormData();
    formData.append("image", image);
    axios.defaults.headers.common = { Authorization: `${authToken}` };

    axios
      .post("/user/image", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then(() => {
        setUpdateImage(false);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          navigate("/signin");
        }
        setError(error.response.data.error);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    AuthMiddleWare(navigate);
    const authToken = localStorage.getItem("AuthToken");
    axios.defaults.headers.common = { Authorization: `${authToken}` };

    const updateDetails = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNumber,
      country: country,
      username: userName,
      level: level,
      affiliation: affiliation,
    };

    console.log(updateDetails);

    axios
      .post("/user", updateDetails)
      .then((response) => {
        if (updateImage) {
          updateProfilePhoto();
        }
        window.location.reload();
      })
      .catch((error) => {
        setError(error.response.data.error);
      });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px",
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Profile
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <ApplicationNavigation />
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={1} align="center" justify="center">
              <Card align="center" justify="center">
                <Box
                  component="form"
                  noValidate
                  onSubmit={handleSubmit}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid
                    container
                    padding={5}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item alignItems="center" justifyContent="center">
                      <Avatar
                        alt={userName}
                        src={imageUrl}
                        sx={{ width: 200, height: 200 }}
                      />
                    </Grid>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        paddingTop={3}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <input
                          accept="image/*"
                          id="raised-button-file"
                          multiple
                          type="file"
                          onChange={onImageChangeHandler}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    margin="normal"
                    paddingTop={1}
                    paddingBottom={1}
                    spacing={2}
                  >
                    {error !== undefined && error !== "" && (
                      <Alert severity="error">{error}</Alert>
                    )}
                  </Grid>
                  <Divider variant="middle" />
                  <Grid
                    container
                    paddingRight={5}
                    paddingLeft={5}
                    paddingTop={1}
                    paddingBottom={10}
                    spacing={2}
                  >
                    <Grid
                      container
                      paddingRight={15}
                      paddingLeft={15}
                      paddingTop={3}
                      paddingBottom={10}
                      spacing={2}
                    >
                      <Grid item xs={12} sm={6}>
                        <TextField
                          autoComplete="given-name"
                          name="firstName"
                          fullWidth
                          margin="normal"
                          id="firstName"
                          helperText="First Name"
                          label={firstName}
                          autoFocus
                          onChange={onFirstNameChangeHandler}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          margin="normal"
                          id="lastName"
                          helperText="Last Name"
                          label={lastName}
                          name="lastName"
                          autoComplete="family-name"
                          autoFocus
                          onChange={onLastNameChangeHandler}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="userName"
                          fullWidth
                          margin="normal"
                          id="userName"
                          helperText="UserName"
                          label={userName}
                          autoFocus
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="userId"
                          fullWidth
                          margin="normal"
                          id="userId"
                          helperText="User Identification"
                          label={userId}
                          autoFocus
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="level"
                          fullWidth
                          margin="normal"
                          helperText="Security Level"
                          id="level"
                          label={level}
                          autoFocus
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          margin="normal"
                          helperText="Affiliation"
                          id="affiliation"
                          label={affiliation}
                          name="affiliation"
                          autoFocus
                          onChange={onAffiliationChangeHandler}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          margin="normal"
                          id="email"
                          helperText="Email Address"
                          label={email}
                          name="email"
                          autoComplete="email"
                          autoFocus
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          margin="normal"
                          name="country"
                          label={country}
                          helperText="Country"
                          type="country"
                          id="country"
                          autoFocus
                          onChange={onCountryChangeHandler}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          type="number"
                          margin="normal"
                          helperText="Phone Number"
                          name="phonenumber"
                          label={phoneNumber}
                          id="phonenumber"
                          autoFocus
                          onChange={onPhoneNumberChangeHandler}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Button
                          autoFocus
                          type="submit"
                          fullWidth
                          variant="contained"
                        >
                          Update
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
