import * as React from "react";
import { useNavigate } from "react-router-dom";
import Iconify from "../components/icons/iconify";
import "typeface-cormorant";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_FUNCTIONS_URL;

const BESICFONT = createTheme({
  typography: {
    fontFamily: ["Cormorant", "serif"].join(","),
  },
});

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://besic.org/">
        besic.org
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function SignUp() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [userName, setUserName] = React.useState("");
  const [affiliation, setAffiliation] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState();
  const [error, setError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [userNameError, setUserNameError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const onEmailChangeHandler = (inputText) => {
    setEmail(inputText.target.value);
  };
  const onPasswordChangeHandler = (inputText) => {
    setPassword(inputText.target.value);
  };
  const onConfirmPasswordChangeHandler = (inputText) => {
    setConfirmPassword(inputText.target.value);
  };
  const onFirstNameChangeHandler = (inputText) => {
    setFirstName(inputText.target.value);
  };
  const onLastNameChangeHandler = (inputText) => {
    setLastName(inputText.target.value);
  };
  const onUserNameChangeHandler = (inputText) => {
    setUserName(inputText.target.value);
  };
  const onAffiliationChangeHandler = (inputText) => {
    setAffiliation(inputText.target.value);
  };
  const onPhoneNumberChangeHandler = (inputText) => {
    setPhoneNumber(inputText.target.value);
  };
  const onCountryChangeHandler = (inputText) => {
    setCountry(inputText.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const signUpDetails = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNumber,
      country: country,
      password: password,
      confirmPassword: confirmPassword,
      username: userName,
      level: "read",
      affiliation: affiliation,
    };

    axios
      .post("/signup", signUpDetails)
      .then((response) => {
        localStorage.setItem("AuthToken", `Bearer ${response.data.token}`);
        setLoading(false);
        navigate("/");
      })
      .catch((error) => {
        setError(error.response.data.error);
        setEmailError(error.response.data.email);
        setPasswordError(error.response.data.confirmPassword);
        setUserNameError(error.response.data.username);
        setLoading(false);
      });
  };

  return (
    <ThemeProvider theme={BESICFONT}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage:
              "url(https://source.unsplash.com/random?wallpapers)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "dark"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 5,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>

            <Typography variant="h2" align="center" gutterBottom>
              BESI-C ADMIN PORTAL
            </Typography>
            {error !== undefined && error !== "" && (
              <Alert severity="error">{error}</Alert>
            )}
            {emailError !== undefined && emailError !== "" && (
              <Alert severity="error">{emailError}</Alert>
            )}
            {passwordError !== undefined && passwordError !== "" && (
              <Alert severity="error">{passwordError}</Alert>
            )}
            {userNameError !== undefined && userNameError !== "" && (
              <Alert severity="error">{userNameError}</Alert>
            )}
            <Grid>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 3 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="given-name"
                      name="firstName"
                      required
                      fullWidth
                      margin="normal"
                      id="firstName"
                      label="First Name"
                      autoFocus
                      onChange={onFirstNameChangeHandler}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      margin="normal"
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      autoComplete="family-name"
                      autoFocus
                      onChange={onLastNameChangeHandler}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="userName"
                      required
                      fullWidth
                      margin="normal"
                      id="userName"
                      label="Username"
                      autoFocus
                      onChange={onUserNameChangeHandler}
                      error={
                        userNameError !== undefined && userNameError !== ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      margin="normal"
                      id="affiliation"
                      label="Affiliation"
                      name="affiliation"
                      autoFocus
                      onChange={onAffiliationChangeHandler}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      margin="normal"
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      onChange={onEmailChangeHandler}
                      error={emailError !== undefined && emailError !== ""}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      margin="normal"
                      name="password"
                      label="Password"
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              <Iconify
                                icon={
                                  showPassword
                                    ? "eva:eye-fill"
                                    : "eva:eye-off-fill"
                                }
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      id="password"
                      autoComplete="new-password"
                      autoFocus
                      onChange={onPasswordChangeHandler}
                      error={
                        passwordError !== undefined && passwordError !== ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      margin="normal"
                      name="confirmpassword"
                      label="Confirm Password"
                      id="confirmpassword"
                      autoComplete="new-password"
                      type={showConfirmPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                setShowConfirmPassword(!showConfirmPassword)
                              }
                              edge="end"
                            >
                              <Iconify
                                icon={
                                  showPassword
                                    ? "eva:eye-fill"
                                    : "eva:eye-off-fill"
                                }
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      autoFocus
                      onChange={onConfirmPasswordChangeHandler}
                      error={
                        passwordError !== undefined && passwordError !== ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      margin="normal"
                      name="country"
                      label="Country"
                      type="country"
                      id="country"
                      autoFocus
                      onChange={onCountryChangeHandler}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      type="number"
                      margin="normal"
                      name="phonenumber"
                      label="Phone Number"
                      id="phonenumber"
                      autoFocus
                      onChange={onPhoneNumberChangeHandler}
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={
                    loading ||
                    email === "" ||
                    password === "" ||
                    firstName === "" ||
                    lastName === "" ||
                    userName === "" ||
                    affiliation === "" ||
                    confirmPassword === "" ||
                    phoneNumber === "" ||
                    country === ""
                  }
                >
                  Sign Up
                </Button>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Link href="/signin" variant="body2">
                      Already have an account? Sign in
                    </Link>
                  </Grid>
                </Grid>
              </Box>
              <Copyright sx={{ mt: 5 }} />
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
