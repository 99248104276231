import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
// import Homepage from "./pages/Homepage";
import HeartBeats from "./pages/HeartBeats";
import Deployments from "./pages/Deployments";
import Relays from "./pages/Relays";
import Profile from "./pages/Profile";
import Basestations from "./pages/Basestations";
import Watches from "./pages/Watches";
import Hotspots from "./pages/Hotspots";
import Boxes from "./pages/Boxes";
import Users from "./pages/Users";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<SignIn />} />
          {/* <Route exact path="/homepage" element={<Homepage />} /> */}
          <Route exact path="/signin" element={<SignIn />} />
          <Route exact path="/signup" element={<SignUp />} />
          <Route exact path="/profile" element={<Profile />} />
          <Route exact path="/users" element={<Users />} />
          <Route exact path="/heartbeats" element={<HeartBeats />} />
          <Route exact path="/deployments" element={<Deployments />} />
          <Route exact path="/boxes" element={<Boxes />} />
          <Route exact path="/basestations" element={<Basestations />} />
          <Route exact path="/relays" element={<Relays />} />
          <Route exact path="/watches" element={<Watches />} />
          <Route exact path="/hotspots" element={<Hotspots />} />
        </Routes>
      </div>
    </Router>
  );
}
export default App;
