import * as React from "react";
import { useNavigate } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getDatabase, query, ref, limitToLast, get } from "firebase/database";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import { ApplicationNavigation } from "../components/navigation";
import { AuthMiddleWare } from "../utils/authMiddleWare";
import axios from "axios";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTHORIZATION_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_IDENTIFICATION,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER,
  appId: process.env.REACT_APP_APPLICATION_IDENTIFICATION,
  measurementId: process.env.REACT_APP_MEASUREMENT_IDENTIFICATION,
};

const firebaseInitialization = initializeApp(firebaseConfig);
const database = getDatabase(firebaseInitialization);

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://besic.org/">
        BESI-C
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 300;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(7),
      },
    }),
  },
}));

const defaultTheme = createTheme();

export default function HeartBeats() {
  const navigate = useNavigate();
  AuthMiddleWare(navigate);

  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const collections = [
    "Accelerometer",
    "Battery",
    "HeartRate",
    "Estimote",
    "Pedometer",
    "Sensors",
    "Settings",
    "Systems",
    "Pain_Activity",
    "Pain_Responses",
    "Follow_Up_Activity",
    "Follow_Up_Responses",
    "End_Of_Day_Activity",
    "End_Of_Day_Responses",
  ];

  function ViewOfData() {
    const [prefix, setPrefix] = React.useState("");
    const [deployment, setDeployment] = React.useState("");
    const [patient1, setPatient1] = React.useState("");
    const [patient2, setPatient2] = React.useState("");
    const [caregiver1, setCaregiver1] = React.useState("");
    const [caregiver2, setCaregiver2] = React.useState("");
    const [watches, setWatches] = React.useState([]);
    const [relays, setRelays] = React.useState([]);
    const [allDeployments, setAllDepoyments] = React.useState({});
    const [deploymentData, setDeploymentData] = React.useState({});
    const [deploymentBoxes, setDeploymentBoxes] = React.useState({});
    const [deploymentWatches, setDeploymentWatches] = React.useState({});
    const [deploymentRelays, setDeploymentRelays] = React.useState({});
    const [watchData, setWatchData] = React.useState({});
    const [relaySerial, setRelaySerial] = React.useState({});
    const [relayData, setRelayData] = React.useState({});

    const onPrefixChange = (inputText) => {
      setPrefix(inputText.target.value.charAt(0));
      setPatient1("");
      setPatient2("");
      setCaregiver1("");
      setCaregiver2("");
      setWatches([]);
      setWatchData({});
      setRelays([]);
      setRelayData({});
      setRelaySerial({});
    };

    const onDeploymentChange = (inputText) => {
      allDeployments.map((allDeployment) => {
        if (allDeployment.deploymentId === inputText.target.value) {
          setDeploymentData(allDeployment);
        }
        return allDeployment;
      });

      setDeployment(inputText.target.value);
      setPatient1("");
      setPatient2("");
      setCaregiver1("");
      setCaregiver2("");
      setWatches([]);
      setWatchData({});
      setRelays([]);
      setRelayData({});
      setRelaySerial({});
    };

    React.useEffect(() => {
      axios
        .get("/deployments")
        .then((response) => {
          if (response.data) {
            setAllDepoyments(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }, []);

    React.useEffect(() => {
      axios
        .get("/boxes")
        .then((response) => {
          if (response.data) {
            setDeploymentBoxes(response.data);

            deploymentData.deploymentBox.map((deploymentBox) => {
              deploymentBoxes.map((boxData) => {
                if (boxData.boxTitle === deploymentBox) {
                  watches.push(boxData.boxWatches);
                  relays.push(boxData.boxRelays);
                }
                return boxData;
              });
              return deploymentBox;
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
      // eslint-disable-next-line
    }, [prefix, deployment]);

    React.useEffect(() => {
      axios
        .get("/relays")
        .then((response) => {
          if (response.data) {
            setDeploymentRelays(response.data);

            new Array(deploymentRelays).map((deploymentRelay) => {
              deploymentRelay.map((eachRelay) => {
                relays.map((relay) => {
                  relay.map((rela) => {
                    if (eachRelay.relayTitle === rela) {
                      setRelaySerial((previousData) => ({
                        ...previousData,
                        [rela]: eachRelay.relaySerialNumber,
                      }));
                    }
                    return rela;
                  });
                  return relay;
                });
                return eachRelay;
              });
              return deploymentRelay;
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
      // eslint-disable-next-line
    }, [prefix, deployment]);

    React.useEffect(() => {
      axios
        .get("/watches")
        .then((response) => {
          if (response.data) {
            setDeploymentWatches(response.data);

            new Array(deploymentWatches).map((deploymentWatch) => {
              deploymentWatch.map((eachWatch) => {
                watches.map((watche) => {
                  watche.map((watch) => {
                    if (eachWatch.watchTitle === watch) {
                      if (eachWatch.watchRole === "PT1") {
                        setPatient1(watch);
                      } else if (eachWatch.watchRole === "PT2") {
                        setPatient2(watch);
                      } else if (eachWatch.watchRole === "CG1") {
                        setCaregiver1(watch);
                      } else if (eachWatch.watchRole === "CG2") {
                        setCaregiver2(watch);
                      }
                    }
                    return watch;
                  });
                  return watche;
                });
                return eachWatch;
              });
              return watches;
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
      // eslint-disable-next-line
    }, [prefix, deployment]);

    React.useEffect(() => {
      const fetchData = async () => {
        Object.entries(relaySerial).forEach(async (relaySerialNumber) => {
          const relayReference = query(
            ref(database, relaySerialNumber[1]),
            limitToLast(1)
          );
          const relayDataFromFirebase = await get(relayReference);

          if (relayDataFromFirebase.exists()) {
            relayDataFromFirebase.forEach((snapshot) => {
              setRelayData((previousData) => ({
                ...previousData,
                [relaySerialNumber[0]]: [snapshot.val()],
              }));
            });
          }
        });

        collections.forEach(async (collection) => {
          const watchReference1 = query(
            ref(database, prefix + "-" + deployment + "-PT1-" + collection),
            limitToLast(1)
          );
          const watchReference2 = query(
            ref(database, prefix + "-" + deployment + "-PT2-" + collection),
            limitToLast(1)
          );
          const watchReference3 = query(
            ref(database, prefix + "-" + deployment + "-CG1-" + collection),
            limitToLast(1)
          );
          const watchReference4 = query(
            ref(database, prefix + "-" + deployment + "-CG2-" + collection),
            limitToLast(1)
          );

          const watchSnapshot1 = await get(watchReference1);
          const watchSnapshot2 = await get(watchReference2);
          const watchSnapshot3 = await get(watchReference3);
          const watchSnapshot4 = await get(watchReference4);

          if (watchSnapshot1.exists()) {
            watchSnapshot1.forEach((snapshot) => {
              setWatchData((previousData) => ({
                ...previousData,
                ["Patient_1_" + collection]: snapshot.val(),
              }));
            });
          }
          if (watchSnapshot2.exists()) {
            watchSnapshot2.forEach((snapshot) => {
              setWatchData((previousData) => ({
                ...previousData,
                ["Patient_2_" + collection]: snapshot.val(),
              }));
            });
          }
          if (watchSnapshot3.exists()) {
            watchSnapshot3.forEach((snapshot) => {
              setWatchData((previousData) => ({
                ...previousData,
                ["Caregiver_1_" + collection]: snapshot.val(),
              }));
            });
          }
          if (watchSnapshot4.exists()) {
            watchSnapshot4.forEach((snapshot) => {
              setWatchData((previousData) => ({
                ...previousData,
                ["Caregiver_2_" + collection]: snapshot.val(),
              }));
            });
          }
        });
      };

      fetchData();

      const intervalId = setInterval(fetchData, 5000); // Currently updates every 5 seconds
      return () => clearInterval(intervalId);
    }, [deployment, prefix, relaySerial, relayData]);

    return (
      <>
        <Grid item xs={6}>
          <FormControl sx={{ m: 1, minWidth: 560 }}>
            <Select
              value={prefix}
              onChange={onPrefixChange}
              displayEmpty
              inputProps={{ "aria-label": "Type" }}
            >
              <MenuItem value="T">
                <em>Test</em>
              </MenuItem>
              <MenuItem value="P">
                <em>Deployment</em>
              </MenuItem>
            </Select>
            <FormHelperText>Prefix (Watch)</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl sx={{ m: 1, minWidth: 560 }}>
            <Select
              value={deployment}
              onChange={onDeploymentChange}
              displayEmpty
              inputProps={{ "aria-label": "Type" }}
            >
              {new Array(allDeployments).map((allDeployment) => {
                if (allDeployment.length !== undefined) {
                  return allDeployment.map((eachDeployment) => {
                    return (
                      <MenuItem value={eachDeployment.deploymentId}>
                        <em>{eachDeployment.deploymentTitle}</em>
                      </MenuItem>
                    );
                  });
                }
                return <div></div>;
              })}
            </Select>
            <FormHelperText>Deployment (Watch + Relay)</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 50 }} aria-label="Relays">
              {relays.map((relay) => {
                return <caption>{relay + ", "}</caption>;
              })}
              <TableHead>
                <TableRow>
                  <TableCell>Relay</TableCell>
                  <TableCell>Relay Current Time</TableCell>
                  <TableCell>Relay Updated Time</TableCell>
                  <TableCell>Humidity</TableCell>
                  <TableCell>Light</TableCell>
                  <TableCell>Temperature</TableCell>
                  <TableCell>Pressure</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(relayData).map((data) => {
                  if (typeof data[1][0].time !== "undefined") {
                    return (
                      <TableRow key={data[0]}>
                        <TableCell>{data[0]}</TableCell>
                        <TableCell>
                          {new Intl.DateTimeFormat("en-US", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                          }).format(data[1][0].time.time)}
                        </TableCell>
                        <TableCell>
                          {new Intl.DateTimeFormat("en-US", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                          }).format(data[1][0].time)}
                        </TableCell>
                        <TableCell>{data[1][0].humidity}</TableCell>
                        <TableCell>{data[1][0].light}</TableCell>
                        <TableCell>{data[1][0].temperature}</TableCell>
                        <TableCell>{data[1][0].pressure}</TableCell>
                      </TableRow>
                    );
                  }
                  return <></>;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={6}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 50 }} aria-label="Patient Watch 1">
              <caption>{patient1} (PT1)</caption>
              <TableHead>
                <TableRow>
                  <TableCell>File</TableCell>
                  <TableCell>Last Updated</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(watchData).map((data) => {
                  if (
                    data[0].includes("Patient_1") &&
                    typeof data[1].time !== "undefined"
                  ) {
                    return (
                      <TableRow key={data[0]}>
                        <TableCell>
                          {data[0]
                            .replaceAll("Patient_1_", patient1 + ": ")
                            .replaceAll("_", " ")}
                        </TableCell>
                        <TableCell>
                          {new Intl.DateTimeFormat("en-US", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                          }).format(data[1].time.time)}
                        </TableCell>
                      </TableRow>
                    );
                  }

                  return <></>;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={6}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 50 }} aria-label="Caregiver Watch 1">
              <caption>{caregiver1} (CG1)</caption>
              <TableHead>
                <TableRow>
                  <TableCell>File</TableCell>
                  <TableCell>Last Updated</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(watchData).map((data) => {
                  if (
                    data[0].includes("Caregiver_1") &&
                    typeof data[1].time !== "undefined"
                  ) {
                    return (
                      <TableRow key={data[0]}>
                        <TableCell>
                          {data[0]
                            .replaceAll("Caregiver_1_", caregiver1 + ": ")
                            .replaceAll("_", " ")}
                        </TableCell>
                        <TableCell>
                          {new Intl.DateTimeFormat("en-US", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                          }).format(data[1].time.time)}
                        </TableCell>
                      </TableRow>
                    );
                  }
                  return <></>;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={6}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 50 }} aria-label="Patient Watch 2">
              <caption>{patient2} (PT2)</caption>
              <TableHead>
                <TableRow>
                  <TableCell>File</TableCell>
                  <TableCell>Last Updated</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(watchData).map((data) => {
                  if (
                    data[0].includes("Patient_2") &&
                    typeof data[1].time !== "undefined"
                  ) {
                    return (
                      <TableRow key={data[0]}>
                        <TableCell>
                          {data[0]
                            .replaceAll("Patient_2_", patient2 + ": ")
                            .replaceAll("_", " ")}
                        </TableCell>
                        <TableCell>
                          {new Intl.DateTimeFormat("en-US", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                          }).format(data[1].time.time)}
                        </TableCell>
                      </TableRow>
                    );
                  }
                  return <></>;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={6}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 50 }} aria-label="Caregiver Watch 2">
              <caption>{caregiver2} (CG2)</caption>
              <TableHead>
                <TableRow>
                  <TableCell>File</TableCell>
                  <TableCell>Last Updated</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(watchData).map((data) => {
                  if (
                    data[0].includes("Caregiver_2") &&
                    typeof data[1].time !== "undefined"
                  ) {
                    return (
                      <TableRow key={data[0]}>
                        <TableCell>
                          {data[0]
                            .replaceAll("Caregiver_2_", caregiver2 + ": ")
                            .replaceAll("_", " ")}
                        </TableCell>
                        <TableCell>
                          {new Intl.DateTimeFormat("en-US", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                          }).format(data[1].time.time)}
                        </TableCell>
                      </TableRow>
                    );
                  }
                  return <></>;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </>
    );
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px",
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              HeartBeats
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <ApplicationNavigation />
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <ViewOfData />
              </Grid>
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
