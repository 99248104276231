import * as React from "react";
import { useNavigate } from "react-router-dom";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Alert from "@mui/material/Alert";
import BlockIcon from "@mui/icons-material/Block";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"; // Can be updated to the attached link once available: https://mui.com/x/react-date-pickers/date-time-range-picker/
import { ApplicationNavigation } from "../components/navigation";
import { AuthMiddleWare } from "../utils/authMiddleWare";

import dayjs from "dayjs";
import axios from "axios";

const drawerWidth = 300;
const defaultTheme = createTheme();

const columns = [
  { id: "firstName", label: "First Name", minWidth: 100 },
  { id: "lastName", label: "Last Name", minWidth: 100 },
  {
    id: "username",
    label: "Username",
    minWidth: 100,
  },
  {
    id: "email",
    label: "E-Mail",
    minWidth: 100,
  },
  {
    id: "phoneNumber",
    label: "Phone Number",
    minWidth: 100,
  },
  {
    id: "affiliation",
    label: "Affiliation",
    minWidth: 100,
  },
  {
    id: "country",
    label: "Country",
    minWidth: 100,
  },
  {
    id: "level",
    label: "Security Level",
    minWidth: 100,
  },
];
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(7),
      },
    }),
  },
}));

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://besic.org/">
        BESI-C
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Watches() {
  const navigate = useNavigate();
  AuthMiddleWare(navigate);

  const [users, setUsers] = React.useState({});
  const [open, setOpen] = React.useState(true);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [currentUserUsername, setCurrentUserUserName] = React.useState("");
  const [currentUserLevel, setCurrentUserLevel] = React.useState("");

  const [userFirstName, setUserFirstName] = React.useState("");
  const [userLastName, setUserLastName] = React.useState("");
  const [userUserName, setUserUserName] = React.useState("");
  const [userPhoneNumber, setUserPhoneNumber] = React.useState("");
  const [userCountry, setUserCountry] = React.useState("");
  const [userLevel, setUserLevel] = React.useState("");
  const [userUserId, setUserUserId] = React.useState("");
  const [userEmail, setUserEmail] = React.useState("");
  const [userAffiliation, setUserAffiliation] = React.useState("");
  const [userCreatedAt, setUserCreatedAt] = React.useState("");

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const handleOpen = () => {
    setOpenPopup(true);
  };

  const handleClose = () => {
    setUserFirstName("");
    setUserLastName("");
    setUserUserName("");
    setUserPhoneNumber("");
    setUserCountry("");
    setUserLevel("");
    setUserUserId("");
    setUserEmail("");
    setUserAffiliation("");
    setUserCreatedAt("");

    setOpenPopup(false);
  };

  const handleOpenPopup = (event) => {
    AuthMiddleWare(navigate);
    const authToken = localStorage.getItem("AuthToken");
    axios.defaults.headers.common = { Authorization: `${authToken}` };

    axios
      .get("/user/" + event)
      .then((response) => {
        setUserFirstName(response.data.firstName);
        setUserLastName(response.data.lastName);
        setUserUserName(response.data.username);
        setUserPhoneNumber(response.data.phoneNumber);
        setUserCountry(response.data.country);
        setUserLevel(response.data.level);
        setUserUserId(response.data.userId);
        setUserEmail(response.data.email);
        setUserAffiliation(response.data.affiliation);
        setUserCreatedAt(response.data.createdAt);
      })
      .catch((error) => {
        console.log(error);
      });

    handleOpen();
  };

  React.useEffect(() => {
    AuthMiddleWare(navigate);
    const authToken = localStorage.getItem("AuthToken");
    axios.defaults.headers.common = { Authorization: `${authToken}` };

    axios
      .get("/users")
      .then((response) => {
        console.log(response);
        setUsers(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [navigate]);

  React.useEffect(() => {
    const getUserData = () => {
      AuthMiddleWare(navigate);
      const authToken = localStorage.getItem("AuthToken");
      axios.defaults.headers.common = { Authorization: `${authToken}` };

      axios
        .get("/user")
        .then((response) => {
          setCurrentUserLevel(response.data.userCredentials.level);
          setCurrentUserUserName(response.data.userCredentials.username);
        })
        .catch((error) => {
          if (error.response.status === 403) {
            navigate("/signin");
          }
        });
    };

    getUserData();
  });

  function TableViewOfData() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [alert, setAlert] = React.useState("");
    const [error, setError] = React.useState("");
    const [edit, setEdit] = React.useState(false);

    const [userLevelToUpdate, setUserLevelToUpdate] = React.useState(userLevel);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
    const handleEdit = () => {
      setError("");
      setAlert("");
      setEdit(!edit);
    };

    const onUserLevelToUpdateChangeHandler = (inputText) => {
      setUserLevelToUpdate(inputText.target.value);
    };

    const handleUpdate = () => {
      AuthMiddleWare(navigate);
      const authToken = localStorage.getItem("AuthToken");
      axios.defaults.headers.common = { Authorization: `${authToken}` };

      const currentUser = {
        firstName: userFirstName,
        lastName: userLastName,
        username: userUserName,
        phoneNumber: userPhoneNumber,
        country: userCountry,
        level: userLevel,
        email: userEmail,
        affiliation: userAffiliation,
        userId: userUserId,
      };

      // Actively choosing not to pass in items from the textfields since they are diabled.
      // Only item updating will be the level which is updated in userLevelToUpdate.
      const updateUser = {
        firstName: userFirstName,
        lastName: userLastName,
        username: userUserName,
        phoneNumber: userPhoneNumber,
        country: userCountry,
        level: userLevelToUpdate,
        email: userEmail,
        affiliation: userAffiliation,
        userId: userUserId,
      };

      if (currentUser.level === updateUser.level) {
        setError("Error: The security level of the user is the same.");
      } else {
        axios
          .put("/user/" + userUserName, updateUser)
          .then((response) => {
            setAlert("Success: " + response.data.message);
            window.location.reload();
          })
          .catch((error) => {
            setError("Error: " + error.response.data.error);
          });
      }

      setEdit(!edit);
    };

    return (
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* eslint-disable-next-line */}
              {new Array(users).map((allUsers) => {
                if (allUsers.length !== undefined) {
                  return (
                    <React.Fragment key={Math.random()}>
                      {allUsers
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((user) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={user.username}
                              onClick={() => handleOpenPopup(user.username)}
                            >
                              {columns.map((column) => {
                                const value = user[column.id];
                                return (
                                  <TableCell key={column.id}>{value}</TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </React.Fragment>
                  );
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[1, 5, 10, 20, 30, 50, 100]}
          component="div"
          count={users.length !== undefined ? users.length : 1}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={openPopup}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            User Actions
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Grid container spacing={1} align="center" justify="center">
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                margin="normal"
                paddingTop={1}
                paddingBottom={1}
                spacing={2}
              ></Grid>
              <Divider variant="middle" />
              <Grid
                container
                paddingRight={5}
                paddingLeft={5}
                paddingTop={1}
                paddingBottom={10}
                spacing={2}
              >
                <Grid
                  container
                  paddingRight={5}
                  paddingLeft={5}
                  paddingTop={3}
                  paddingBottom={10}
                  spacing={2}
                >
                  <Grid item xs={12}>
                    {alert !== "" && (
                      <Alert margin="normal" severity="success">
                        {alert}
                      </Alert>
                    )}
                    {error !== "" && (
                      <Alert margin="normal" severity="error">
                        {error}
                      </Alert>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        label="Created Date"
                        defaultValue={dayjs(userCreatedAt)}
                        disabled
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="userFirstName"
                      fullWidth
                      margin="normal"
                      id="userFirstName"
                      helperText="First Name"
                      label={userFirstName}
                      autoFocus
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="userLastName"
                      fullWidth
                      margin="normal"
                      id="userLastName"
                      helperText="Last Name"
                      label={userLastName}
                      autoFocus
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="userUserName"
                      fullWidth
                      margin="normal"
                      id="userUserName"
                      helperText="UserName"
                      label={userUserName}
                      autoFocus
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="userPhoneNumber"
                      fullWidth
                      margin="normal"
                      id="userPhoneNumber"
                      helperText="Phone Number"
                      label={userPhoneNumber}
                      autoFocus
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="userEmail"
                      fullWidth
                      margin="normal"
                      id="userEmail"
                      helperText="E-Mail"
                      label={userEmail}
                      autoFocus
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="userUserId"
                      fullWidth
                      margin="normal"
                      id="userUserId"
                      helperText="User ID"
                      label={userUserId}
                      autoFocus
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="userCountry"
                      fullWidth
                      margin="normal"
                      id="userCountry"
                      helperText="Country"
                      label={userCountry}
                      autoFocus
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="userAffiliation"
                      fullWidth
                      margin="normal"
                      id="userAffiliation"
                      helperText="Affiliation"
                      label={userAffiliation}
                      autoFocus
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <Select
                        value={userLevelToUpdate}
                        onChange={onUserLevelToUpdateChangeHandler}
                        displayEmpty
                        disabled={!edit}
                        inputProps={{ "aria-label": "Level" }}
                      >
                        <MenuItem value="read">
                          <em>Read</em>
                        </MenuItem>
                        <MenuItem value="write">
                          <em>Write</em>
                        </MenuItem>
                        <MenuItem value="admin">
                          <em>Admin</em>
                        </MenuItem>
                      </Select>
                      <FormHelperText>Security Level</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {currentUserLevel === "admin" &&
            userUserName !== currentUserUsername ? (
              edit === true ? (
                <Button autoFocus onClick={handleEdit}>
                  Preview
                </Button>
              ) : (
                <Button autoFocus onClick={handleEdit}>
                  Edit
                </Button>
              )
            ) : (
              <></>
            )}
            {currentUserLevel === "admin" &&
            userUserName !== currentUserUsername ? (
              <Button autoFocus onClick={handleUpdate}>
                Update
              </Button>
            ) : (
              <></>
            )}
          </DialogActions>
        </BootstrapDialog>
      </Paper>
    );
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px",
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Users
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <ApplicationNavigation />
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid
              container
              spacing={1}
              alignItems="center"
              justifyContent="center"
            >
              <Card align="center" justify="center">
                <Box
                  component="form"
                  noValidate
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid
                    container
                    padding={5}
                    alignItems="center"
                    justifyContent="center"
                  >
                    {currentUserLevel === "admin" ? (
                      <TableViewOfData />
                    ) : (
                      <>
                        <BlockIcon />
                        <TextField
                          name="notAuthorized"
                          fullWidth
                          margin="normal"
                          id="notAuthorized"
                          label="User Is Not Authorized"
                          autoFocus
                          disabled
                        />
                      </>
                    )}
                  </Grid>
                </Box>
              </Card>
            </Grid>
            {currentUserLevel === "admin" ? (
              <Copyright sx={{ pt: 4 }} />
            ) : (
              <></>
            )}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
