import * as React from "react";
import { useNavigate } from "react-router-dom";
import Iconify from "../components/icons/iconify";
import "typeface-cormorant";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Alert from "@mui/material/Alert";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_FUNCTIONS_URL;

const BESICFONT = createTheme({
  typography: {
    fontFamily: ["Cormorant", "serif"].join(","),
  },
});

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://besic.org/">
        besic.org
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function SignIn() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const onEmailChangeHandler = (inputText) => {
    setEmail(inputText.target.value);
  };
  const onPasswordChangeHandler = (inputText) => {
    setPassword(inputText.target.value);
  };

  const handleSignIn = (event) => {
    event.preventDefault();
    const signInDetails = {
      email: email,
      password: password,
    };

    axios
      .post("/signin", signInDetails)
      .then((response) => {
        localStorage.setItem("AuthToken", `Bearer ${response.data.token}`);
        setLoading(false);
        navigate("/heartbeats");
      })
      .catch((error) => {
        setError(error.response.data.error);
        setLoading(false);
      });
  };

  return (
    <ThemeProvider theme={BESICFONT}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage:
              "url(https://source.unsplash.com/random?wallpapers)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "dark"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 30,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>

            <Typography variant="h2" align="center" gutterBottom>
              BESI-C ADMIN PORTAL
            </Typography>
            {error !== "" && <Alert severity="error">{error}</Alert>}
            <Grid>
              <Box
                component="form"
                noValidate
                onSubmit={handleSignIn}
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  autoComplete="email"
                  name="email"
                  label="Email address"
                  onChange={onEmailChangeHandler}
                  autoFocus
                  error={error !== ""}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  id="password"
                  autoComplete="current-password"
                  onChange={onPasswordChangeHandler}
                  type={showPassword ? "text" : "password"}
                  error={error !== ""}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          <Iconify
                            icon={
                              showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={loading || email === "" || password === ""}
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign In
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link
                      href="mailto:inertia.besic@gmail.com"
                      variant="subtitle2"
                      underline="hover"
                    >
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item>
                    {/* <Link href="/signup" variant="body2">
                      {"Don't have an account? Sign Up"}
                    </Link> */}
                  </Grid>
                </Grid>
              </Box>
              <Copyright sx={{ mt: 5 }} />
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
