import * as React from "react";
import { useNavigate } from "react-router-dom";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Alert from "@mui/material/Alert";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"; // Can be updated to the attached link once available: https://mui.com/x/react-date-pickers/date-time-range-picker/
import { ApplicationNavigation } from "../components/navigation";
import { AuthMiddleWare } from "../utils/authMiddleWare";

import dayjs from "dayjs";
import axios from "axios";

const drawerWidth = 300;
const defaultTheme = createTheme();

const columns = [
  { id: "watchTitle", label: "Name", minWidth: 170 },
  { id: "watchId", label: "ID", minWidth: 10 },
  {
    id: "watchMacAddress",
    label: "Mac Address",
    minWidth: 170,
  },
  {
    id: "watchSerialNumber",
    label: "Serial Number",
    minWidth: 170,
  },
  {
    id: "watchBrand",
    label: "Brand",
    minWidth: 50,
  },
  {
    id: "watchColor",
    label: "Color",
    minWidth: 50,
  },
  {
    id: "watchStatus",
    label: "Status",
    minWidth: 50,
  },
  {
    id: "watchType",
    label: "Type",
    minWidth: 150,
  },
  {
    id: "watchRole",
    label: "Role",
    minWidth: 50,
  },
];
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(7),
      },
    }),
  },
}));

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://besic.org/">
        BESI-C
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Watches() {
  const navigate = useNavigate();
  AuthMiddleWare(navigate);

  const [watches, setWatches] = React.useState({});
  const [userLevel, setUserLevel] = React.useState("");
  const [open, setOpen] = React.useState(true);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [openNewWatch, setOpenNewWatch] = React.useState(false);
  const [watchAvailable, setWatchAvailable] = React.useState(false);

  const [documentId, setDocumentId] = React.useState("");
  const [watchId, setWatchId] = React.useState("");
  const [watchTitle, setWatchTitle] = React.useState("");
  const [watchCreatedDate, setWatchCreatedDate] = React.useState("");
  const [watchBrand, setWatchBrand] = React.useState("");
  const [watchColor, setWatchColor] = React.useState("");
  const [watchRole, setWatchRole] = React.useState("");
  const [watchSerialNumber, setWatchSerialNumber] = React.useState("");
  const [watchMacAddress, setWatchMacAddress] = React.useState("");
  const [watchStatus, setWatchStatus] = React.useState("");
  const [watchType, setWatchType] = React.useState("");
  const [watchNotes, setWatchNotes] = React.useState("");

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const handleOpen = () => {
    setOpenPopup(true);
  };

  const handleClose = () => {
    setDocumentId("");
    setWatchId("");
    setWatchTitle("");
    setWatchCreatedDate("");
    setWatchBrand("");
    setWatchColor("");
    setWatchRole("");
    setWatchSerialNumber("");
    setWatchMacAddress("");
    setWatchStatus("");
    setWatchType("");
    setWatchNotes("");

    setOpenPopup(false);
    setOpenNewWatch(false);
  };

  const handleNewWatch = () => {
    setDocumentId("");
    setWatchCreatedDate(new Date().toISOString());
    setOpenNewWatch(true);
  };

  const handleOpenPopup = (event) => {
    AuthMiddleWare(navigate);
    const authToken = localStorage.getItem("AuthToken");
    axios.defaults.headers.common = { Authorization: `${authToken}` };

    axios
      .get("/watches/" + event)
      .then((response) => {
        setDocumentId(response.data.documentId);
        setWatchId(response.data.watchId);
        setWatchTitle(response.data.watchTitle);
        setWatchCreatedDate(response.data.watchCreatedDate);
        setWatchBrand(response.data.watchBrand);
        setWatchColor(response.data.watchColor);
        setWatchRole(response.data.watchRole);
        setWatchSerialNumber(response.data.watchSerialNumber);
        setWatchMacAddress(response.data.watchMacAddress);
        setWatchStatus(response.data.watchStatus);
        setWatchType(response.data.watchType);
        setWatchNotes(response.data.watchNotes);

        setWatchAvailable(true);
        if (response.data.watchStatus === "active") {
          setWatchAvailable(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    handleOpen();
  };

  React.useEffect(() => {
    AuthMiddleWare(navigate);
    const authToken = localStorage.getItem("AuthToken");
    axios.defaults.headers.common = { Authorization: `${authToken}` };

    axios
      .get("/watches")
      .then((response) => {
        setWatches(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [navigate]);

  React.useEffect(() => {
    const getUserData = () => {
      AuthMiddleWare(navigate);
      const authToken = localStorage.getItem("AuthToken");
      axios.defaults.headers.common = { Authorization: `${authToken}` };

      axios
        .get("/user")
        .then((response) => {
          setUserLevel(response.data.userCredentials.level);
        })
        .catch((error) => {
          if (error.response.status === 403) {
            navigate("/signin");
          }
        });
    };

    getUserData();
  });

  function TableViewOfData() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [alert, setAlert] = React.useState("");
    const [error, setError] = React.useState("");
    const [edit, setEdit] = React.useState(false);

    const [watchIdToSend, setWatchIdToSend] = React.useState("");
    const [watchIdToUpdate, setWatchIdToUpdate] = React.useState(watchId);
    const [watchTitleToSend, setWatchTitleToSend] = React.useState("");
    const [watchTitleToUpdate, setWatchTitleToUpdate] = React.useState(
      watchTitle
    );
    const [watchBrandToSend, setWatchBrandToSend] = React.useState("");
    const [watchBrandToUpdate, setWatchBrandToUpdate] = React.useState(
      watchBrand
    );
    const [watchColorToSend, setWatchColorToSend] = React.useState("");
    const [watchColorToUpdate, setWatchColorToUpdate] = React.useState(
      watchColor
    );
    const [watchRoleToSend, setWatchRoleToSend] = React.useState("");
    const [watchRoleToUpdate, setWatchRoleToUpdate] = React.useState(watchRole);
    const [watchStatusToSend, setWatchStatusToSend] = React.useState(
      "available"
    );
    const [watchStatusToUpdate, setWatchStatusToUpdate] = React.useState(
      watchStatus
    );
    const [watchTypeToSend, setWatchTypeToSend] = React.useState("");
    const [watchTypeToUpdate, setWatchTypeToUpdate] = React.useState(watchType);
    const [watchMacAddressToSend, setWatchMacAddressToSend] = React.useState(
      ""
    );
    const [
      watchMacAddressToUpdate,
      setWatchMacAddressToUpdate,
    ] = React.useState(watchMacAddress);
    const [
      watchSerialNumberToSend,
      setWatchSerialNumberToSend,
    ] = React.useState("");
    const [
      watchSerialNumberToUpdate,
      setWatchSerialNumberToUpdate,
    ] = React.useState(watchSerialNumber);
    const [watchNotesToSend, setWatchNotesToSend] = React.useState("");
    const [watchNotesToUpdate, setWatchNotesToUpdate] = React.useState(
      watchNotes
    );

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
    const handleEdit = () => {
      setError("");
      setAlert("");
      setEdit(!edit);
    };
    const handleDelete = () => {
      setError("");
      setAlert("");

      AuthMiddleWare(navigate);
      const authToken = localStorage.getItem("AuthToken");
      axios.defaults.headers.common = { Authorization: `${authToken}` };

      axios
        .delete("/watches/" + documentId)
        .then((response) => {
          setAlert("Success: " + response.data.message);
          window.location.reload();
        })
        .catch((error) => {
          setError("Error: " + error.response.data.error);
        });
    };

    const onWatchIdToSendChangeHandler = (inputText) => {
      setWatchIdToSend(inputText.target.value);
    };
    const onWatchIdToUpdateChangeHandler = (inputText) => {
      setWatchIdToUpdate(inputText.target.value);
    };
    const onWatchTitleToSendChangeHandler = (inputText) => {
      setWatchTitleToSend(inputText.target.value);
    };
    const onWatchTitleToUpdateChangeHandler = (inputText) => {
      setWatchTitleToUpdate(inputText.target.value);
    };
    const onWatchBrandToSendChangeHandler = (inputText) => {
      setWatchBrandToSend(inputText.target.value);
    };
    const onWatchBrandToUpdateChangeHandler = (inputText) => {
      setWatchBrandToUpdate(inputText.target.value);
    };
    const onWatchColorToSendChangeHandler = (inputText) => {
      setWatchColorToSend(inputText.target.value);
    };
    const onWatchColorToUpdateChangeHandler = (inputText) => {
      setWatchColorToUpdate(inputText.target.value);
    };
    const onWatchRoleToSendChangeHandler = (inputText) => {
      setWatchRoleToSend(inputText.target.value);
    };
    const onWatchRoleToUpdateChangeHandler = (inputText) => {
      setWatchRoleToUpdate(inputText.target.value);
    };
    const onWatchStatusToSendChangeHandler = (inputText) => {
      setWatchStatusToSend(inputText.target.value);
    };
    const onWatchStatusToUpdateChangeHandler = (inputText) => {
      setWatchStatusToUpdate(inputText.target.value);
    };
    const onWatchTypeToSendChangeHandler = (inputText) => {
      setWatchTypeToSend(inputText.target.value);
    };
    const onWatchTypeToUpdateChangeHandler = (inputText) => {
      setWatchTypeToUpdate(inputText.target.value);
    };
    const onWatchSerialNumberToSendChangeHandler = (inputText) => {
      setWatchSerialNumberToSend(inputText.target.value);
    };
    const onWatchSerialNumberToUpdateChangeHandler = (inputText) => {
      setWatchSerialNumberToUpdate(inputText.target.value);
    };
    const onWatchMacAddressToSendChangeHandler = (inputText) => {
      setWatchMacAddressToSend(inputText.target.value);
    };
    const onWatchMacAddressToUpdateChangeHandler = (inputText) => {
      setWatchMacAddressToUpdate(inputText.target.value);
    };
    const onWatchNotesToSendChangeHandler = (inputText) => {
      setWatchNotesToSend(inputText.target.value);
    };
    const onWatchNotesToUpdateChangeHandler = (inputText) => {
      setWatchNotesToUpdate(inputText.target.value);
    };

    const handleSave = () => {
      setError("");
      setAlert("");

      AuthMiddleWare(navigate);
      const authToken = localStorage.getItem("AuthToken");
      axios.defaults.headers.common = { Authorization: `${authToken}` };

      const newWatch = {
        watchId: watchIdToSend,
        watchTitle: watchTitleToSend,
        watchColor: watchColorToSend,
        watchBrand: watchBrandToSend,
        watchStatus: watchStatusToSend,
        watchType: watchTypeToSend,
        watchSerialNumber: watchSerialNumberToSend,
        watchMacAddress: watchMacAddressToSend,
        watchNotes: watchNotesToSend,
        watchRole: watchRoleToSend,
      };

      axios
        .post("/watches", newWatch)
        .then((response) => {
          setWatchIdToSend("");
          setWatchTitleToSend("");
          setWatchColorToSend("");
          setWatchBrandToSend("");
          setWatchStatusToSend("");
          setWatchTypeToSend("");
          setWatchSerialNumberToSend("");
          setWatchMacAddressToSend("");
          setWatchNotesToSend("");
          setWatchRoleToSend("");

          setAlert(
            "Watch " + response.data.documentId + " successfully created!"
          );
          window.location.reload();
        })
        .catch((error) => {
          setError("Error: " + error.response.data.error);
        });
    };

    const handleUpdate = () => {
      AuthMiddleWare(navigate);
      const authToken = localStorage.getItem("AuthToken");
      axios.defaults.headers.common = { Authorization: `${authToken}` };

      const currentWatch = {
        watchId: watchId,
        watchTitle: watchTitle,
        watchColor: watchColor,
        watchBrand: watchBrand,
        watchStatus: watchStatus,
        watchType: watchType,
        watchSerialNumber: watchSerialNumber,
        watchMacAddress: watchMacAddress,
        watchNotes: watchNotes,
        watchRole: watchRole,
      };

      const updateWatch = {
        watchId: watchIdToUpdate,
        watchTitle: watchTitleToUpdate,
        watchColor: watchColorToUpdate,
        watchBrand: watchBrandToUpdate,
        watchStatus: watchStatusToUpdate,
        watchType: watchTypeToUpdate,
        watchSerialNumber: watchSerialNumberToUpdate,
        watchMacAddress: watchMacAddressToUpdate,
        watchNotes: watchNotesToUpdate,
        watchRole: watchRoleToUpdate,
      };

      if (
        currentWatch.watchId === updateWatch.watchId &&
        currentWatch.watchTitle === updateWatch.watchTitle &&
        currentWatch.watchColor === updateWatch.watchColor &&
        currentWatch.watchBrand === updateWatch.watchBrand &&
        currentWatch.watchStatus === updateWatch.watchStatus &&
        currentWatch.watchType === updateWatch.watchType &&
        currentWatch.watchSerialNumber === updateWatch.watchSerialNumber &&
        currentWatch.watchNotes === updateWatch.watchNotes &&
        currentWatch.watchMacAddress === updateWatch.watchMacAddress &&
        currentWatch.watchRole === updateWatch.watchRole
      ) {
        setError("Error: Please make a change before updating the watch");
      } else {
        axios
          .put("/watches/" + documentId, updateWatch)
          .then((response) => {
            setAlert("Success: " + response.data.message);
            window.location.reload();
          })
          .catch((error) => {
            setError("Error: " + error.response.data.error);
          });
      }

      setEdit(!edit);
    };

    return (
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* eslint-disable-next-line */}
              {new Array(watches).map((allWatches) => {
                if (allWatches.length !== undefined) {
                  return (
                    <React.Fragment key={Math.random()}>
                      {allWatches
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((watch) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={watch.documentId}
                              onClick={() => handleOpenPopup(watch.documentId)}
                            >
                              {columns.map((column) => {
                                const value = watch[column.id];
                                return (
                                  <TableCell key={column.id}>{value}</TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </React.Fragment>
                  );
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[1, 5, 10, 20, 30, 50, 100]}
          component="div"
          count={watches.length !== undefined ? watches.length : 1}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={openPopup || openNewWatch}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Watch Actions
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Grid container spacing={1} align="center" justify="center">
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                margin="normal"
                paddingTop={1}
                paddingBottom={1}
                spacing={2}
              ></Grid>
              <Divider variant="middle" />
              <Grid
                container
                paddingRight={5}
                paddingLeft={5}
                paddingTop={1}
                paddingBottom={10}
                spacing={2}
              >
                <Grid
                  container
                  paddingRight={5}
                  paddingLeft={5}
                  paddingTop={3}
                  paddingBottom={10}
                  spacing={2}
                >
                  <Grid item xs={12}>
                    {alert !== "" && (
                      <Alert margin="normal" severity="success">
                        {alert}
                      </Alert>
                    )}
                    {error !== "" && (
                      <Alert margin="normal" severity="error">
                        {error}
                      </Alert>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="watchId"
                      fullWidth
                      margin="normal"
                      id="watchId"
                      helperText="Watch ID"
                      type="number"
                      label={watchId !== "" ? watchIdToUpdate : watchIdToSend}
                      autoFocus
                      onChange={
                        documentId !== ""
                          ? onWatchIdToUpdateChangeHandler
                          : onWatchIdToSendChangeHandler
                      }
                      disabled={!edit && !openNewWatch}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      margin="normal"
                      id="watchTitle"
                      helperText="Watch Name"
                      label={
                        watchTitle !== ""
                          ? watchTitleToUpdate
                          : watchTitleToSend
                      }
                      name="watchTitle"
                      autoFocus
                      onChange={
                        documentId !== ""
                          ? onWatchTitleToUpdateChangeHandler
                          : onWatchTitleToSendChangeHandler
                      }
                      disabled={!edit && !openNewWatch}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="watchMacAddress"
                      fullWidth
                      margin="normal"
                      id="watchMacAddress"
                      helperText="Watch Mac Address"
                      label={
                        watchMacAddress !== ""
                          ? watchMacAddressToUpdate
                          : watchMacAddressToSend
                      }
                      autoFocus
                      onChange={
                        documentId !== ""
                          ? onWatchMacAddressToUpdateChangeHandler
                          : onWatchMacAddressToSendChangeHandler
                      }
                      disabled={!edit && !openNewWatch}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="watchSerialNumber"
                      fullWidth
                      margin="normal"
                      id="watchSerialNumber"
                      helperText="Watch Serial Number"
                      label={
                        watchSerialNumber !== ""
                          ? watchSerialNumberToUpdate
                          : watchSerialNumberToSend
                      }
                      autoFocus
                      onChange={
                        documentId !== ""
                          ? onWatchSerialNumberToUpdateChangeHandler
                          : onWatchSerialNumberToSendChangeHandler
                      }
                      disabled={!edit && !openNewWatch}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="documentId"
                      fullWidth
                      margin="normal"
                      id="documentId"
                      helperText="Watch Unique ID"
                      label={documentId}
                      autoFocus
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        label="Created Date"
                        defaultValue={dayjs(watchCreatedDate)}
                        disabled
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <Select
                        value={
                          watchType !== "" ? watchTypeToUpdate : watchTypeToSend
                        }
                        onChange={
                          documentId !== ""
                            ? onWatchTypeToUpdateChangeHandler
                            : onWatchTypeToSendChangeHandler
                        }
                        displayEmpty
                        disabled={!edit && !openNewWatch}
                        inputProps={{ "aria-label": "Type" }}
                      >
                        <MenuItem value="testing">
                          <em>Testing</em>
                        </MenuItem>
                        <MenuItem value="not tested">
                          <em>Not Tested</em>
                        </MenuItem>
                        <MenuItem value="verified">
                          <em>Verified</em>
                        </MenuItem>
                      </Select>
                      <FormHelperText>Type</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <Select
                        value={
                          watchStatus !== ""
                            ? watchStatusToUpdate
                            : watchStatusToSend
                        }
                        onChange={
                          documentId !== ""
                            ? onWatchStatusToUpdateChangeHandler
                            : onWatchStatusToSendChangeHandler
                        }
                        displayEmpty
                        disabled={(!edit && !openNewWatch) || !watchAvailable}
                        inputProps={{ "aria-label": "Status" }}
                      >
                        <MenuItem value="unknown">
                          <em>Unknown</em>
                        </MenuItem>
                        <MenuItem value="available">
                          <em>Available</em>
                        </MenuItem>
                        <MenuItem value="broken">
                          <em>Broken</em>
                        </MenuItem>
                        <MenuItem value="retired">
                          <em>Retired</em>
                        </MenuItem>
                        {!watchAvailable ? (
                          <MenuItem value="active">
                            <em>Active</em>
                          </MenuItem>
                        ) : (
                          <></>
                        )}
                      </Select>
                      <FormHelperText>Status</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <Select
                        value={
                          watchBrand !== ""
                            ? watchBrandToUpdate
                            : watchBrandToSend
                        }
                        onChange={
                          documentId !== ""
                            ? onWatchBrandToUpdateChangeHandler
                            : onWatchBrandToSendChangeHandler
                        }
                        displayEmpty
                        disabled={!edit && !openNewWatch}
                        inputProps={{ "aria-label": "Brand" }}
                      >
                        <MenuItem value="unknown">
                          <em>Unknown</em>
                        </MenuItem>
                        <MenuItem value="apple">
                          <em>Apple</em>
                        </MenuItem>
                        <MenuItem value="fossil">
                          <em>Fossil</em>
                        </MenuItem>
                        <MenuItem value="samsung">
                          <em>Samsung</em>
                        </MenuItem>
                      </Select>
                      <FormHelperText>Brand</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <Select
                        value={
                          watchColor !== ""
                            ? watchColorToUpdate
                            : watchColorToSend
                        }
                        onChange={
                          documentId !== ""
                            ? onWatchColorToUpdateChangeHandler
                            : onWatchColorToSendChangeHandler
                        }
                        displayEmpty
                        disabled={!edit && !openNewWatch}
                        inputProps={{ "aria-label": "Color" }}
                      >
                        <MenuItem value="unknown">
                          <em>Unknown</em>
                        </MenuItem>
                        <MenuItem value="black">
                          <em>Black</em>
                        </MenuItem>
                        <MenuItem value="silver">
                          <em>Silver</em>
                        </MenuItem>
                        <MenuItem value="white">
                          <em>White</em>
                        </MenuItem>
                        <MenuItem value="grey">
                          <em>Grey</em>
                        </MenuItem>
                      </Select>
                      <FormHelperText>Color</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <Select
                        value={
                          watchRole !== "" ? watchRoleToUpdate : watchRoleToSend
                        }
                        onChange={
                          documentId !== ""
                            ? onWatchRoleToUpdateChangeHandler
                            : onWatchRoleToSendChangeHandler
                        }
                        displayEmpty
                        fullWidth
                        disabled={!edit && !openNewWatch}
                        inputProps={{ "aria-label": "Role" }}
                      >
                        <MenuItem value="none">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="PT Demo">
                          <em>Patient Demo</em>
                        </MenuItem>
                        <MenuItem value="CG Demo">
                          <em>Caregiver Demo</em>
                        </MenuItem>
                        <MenuItem value="PT1">
                          <em>Patient 1</em>
                        </MenuItem>
                        <MenuItem value="PT2">
                          <em>Patient 2</em>
                        </MenuItem>
                        <MenuItem value="CG1">
                          <em>Caregiver 1</em>
                        </MenuItem>
                        <MenuItem value="CG2">
                          <em>Caregiver 2</em>
                        </MenuItem>
                      </Select>
                      <FormHelperText>Role</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="notes"
                      fullWidth
                      margin="normal"
                      id="notes"
                      helperText="Notes"
                      label={
                        watchNotes !== ""
                          ? watchNotesToUpdate
                          : watchNotesToSend
                      }
                      onChange={
                        documentId !== ""
                          ? onWatchNotesToUpdateChangeHandler
                          : onWatchNotesToSendChangeHandler
                      }
                      autoFocus
                      multiline
                      disabled={!edit && !openNewWatch}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {openNewWatch === false && userLevel === "admin" ? (
              <Button autoFocus onClick={handleDelete}>
                Delete
              </Button>
            ) : (
              <></>
            )}

            {openNewWatch === false &&
            (userLevel === "admin" || userLevel === "write") ? (
              edit === true ? (
                <Button autoFocus onClick={handleEdit}>
                  Preview
                </Button>
              ) : (
                <Button autoFocus onClick={handleEdit}>
                  Edit
                </Button>
              )
            ) : (
              <></>
            )}
            {documentId !== "" &&
            (userLevel === "admin" || userLevel === "write") ? (
              <Button autoFocus onClick={handleUpdate}>
                Update
              </Button>
            ) : (
              <></>
            )}
            {documentId === "" &&
            (userLevel === "admin" || userLevel === "write") ? (
              <Button autoFocus onClick={handleSave}>
                Save
              </Button>
            ) : (
              <></>
            )}
          </DialogActions>
        </BootstrapDialog>
      </Paper>
    );
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px",
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Watches
            </Typography>
            {userLevel !== "read" ? (
              <Typography
                component="h2"
                variant="h6"
                color="inherit"
                noWrap
                onClick={handleNewWatch}
              >
                Create Watch
              </Typography>
            ) : (
              <></>
            )}
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <ApplicationNavigation />
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid
              container
              spacing={1}
              alignItems="center"
              justifyContent="center"
            >
              <Card align="center" justify="center">
                <Box
                  component="form"
                  noValidate
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid
                    container
                    padding={5}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <TableViewOfData />
                  </Grid>
                </Box>
              </Card>
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
