import * as React from "react";
import { useNavigate } from "react-router-dom";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Alert from "@mui/material/Alert";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"; // Can be updated to the attached link once available: https://mui.com/x/react-date-pickers/date-time-range-picker/
import { ApplicationNavigation } from "../components/navigation";
import { AuthMiddleWare } from "../utils/authMiddleWare";

import dayjs from "dayjs";
import axios from "axios";

const drawerWidth = 300;
const defaultTheme = createTheme();

const columns = [
  { id: "relayTitle", label: "Name", minWidth: 170 },
  { id: "relayId", label: "ID", minWidth: 10 },
  {
    id: "relayCreatedDate",
    label: "Created Date",
    minWidth: 170,
  },
  {
    id: "relayMacAddress",
    label: "Mac Address",
    minWidth: 170,
  },
  {
    id: "relaySerialNumber",
    label: "Serial Number",
    minWidth: 170,
  },
  {
    id: "relayStatus",
    label: "Status",
    minWidth: 50,
  },
  {
    id: "relayType",
    label: "Type",
    minWidth: 150,
  },
];
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(7),
      },
    }),
  },
}));

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://besic.org/">
        BESI-C
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Relays() {
  const navigate = useNavigate();
  AuthMiddleWare(navigate);

  const [relays, setRelays] = React.useState({});
  const [userLevel, setUserLevel] = React.useState("");
  const [open, setOpen] = React.useState(true);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [openNewRelay, setOpenNewRelay] = React.useState(false);
  const [relayAvailable, setRelayAvailable] = React.useState(false);

  const [documentId, setDocumentId] = React.useState("");
  const [relayId, setRelayId] = React.useState("");
  const [relayTitle, setRelayTitle] = React.useState("");
  const [relayCreatedDate, setRelayCreatedDate] = React.useState("");
  const [relayMacAddress, setRelayMacAddress] = React.useState("");
  const [relaySerialNumber, setRelaySerialNumber] = React.useState("");
  const [relayStatus, setRelayStatus] = React.useState("");
  const [relayType, setRelayType] = React.useState("");
  const [relayNotes, setRelayNotes] = React.useState("");

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const handleOpen = () => {
    setOpenPopup(true);
  };

  const handleClose = () => {
    setDocumentId("");
    setRelayId("");
    setRelayTitle("");
    setRelayCreatedDate("");
    setRelayStatus("");
    setRelayMacAddress("");
    setRelaySerialNumber("");
    setRelayType("");
    setRelayNotes("");

    setOpenPopup(false);
    setOpenNewRelay(false);
  };

  const handleNewRelay = () => {
    setDocumentId("");
    setRelayCreatedDate(new Date().toISOString());
    setOpenNewRelay(true);
  };

  const handleOpenPopup = (event) => {
    AuthMiddleWare(navigate);
    const authToken = localStorage.getItem("AuthToken");
    axios.defaults.headers.common = { Authorization: `${authToken}` };

    axios
      .get("/relays/" + event)
      .then((response) => {
        setDocumentId(response.data.documentId);
        setRelayId(response.data.relayId);
        setRelayTitle(response.data.relayTitle);
        setRelayCreatedDate(response.data.relayCreatedDate);
        setRelayMacAddress(response.data.relayMacAddress);
        setRelaySerialNumber(response.data.relaySerialNumber);
        setRelayStatus(response.data.relayStatus);
        setRelayType(response.data.relayType);
        setRelayNotes(response.data.relayNotes);

        setRelayAvailable(true);
        if (response.data.relayStatus === "active") {
          setRelayAvailable(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    handleOpen();
  };

  React.useEffect(() => {
    AuthMiddleWare(navigate);
    const authToken = localStorage.getItem("AuthToken");
    axios.defaults.headers.common = { Authorization: `${authToken}` };

    axios
      .get("/relays")
      .then((response) => {
        setRelays(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [navigate]);

  React.useEffect(() => {
    const getUserData = () => {
      AuthMiddleWare(navigate);
      const authToken = localStorage.getItem("AuthToken");
      axios.defaults.headers.common = { Authorization: `${authToken}` };

      axios
        .get("/user")
        .then((response) => {
          setUserLevel(response.data.userCredentials.level);
        })
        .catch((error) => {
          if (error.response.status === 403) {
            navigate("/signin");
          }
        });
    };

    getUserData();
  });

  function TableViewOfData() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [alert, setAlert] = React.useState("");
    const [error, setError] = React.useState("");
    const [edit, setEdit] = React.useState(false);

    const [relayIdToSend, setRelayIdToSend] = React.useState("");
    const [relayIdToUpdate, setRelayIdToUpdate] = React.useState(relayId);
    const [relayTitleToSend, setRelayTitleToSend] = React.useState("");
    const [relayTitleToUpdate, setRelayTitleToUpdate] = React.useState(
      relayTitle
    );
    const [relayMacAddressToSend, setRelayMacAddressToSend] = React.useState(
      ""
    );
    const [
      relayMacAddressToUpdate,
      setRelayMacAddressToUpdate,
    ] = React.useState(relayMacAddress);
    const [
      relaySerialNumberToSend,
      setRelaySerialNumberToSend,
    ] = React.useState("");
    const [
      relaySerialNumberToUpdate,
      setRelaySerialNumberToUpdate,
    ] = React.useState(relaySerialNumber);
    const [relayStatusToSend, setRelayStatusToSend] = React.useState(
      "available"
    );
    const [relayStatusToUpdate, setRelayStatusToUpdate] = React.useState(
      relayStatus
    );
    const [relayTypeToSend, setRelayTypeToSend] = React.useState("");
    const [relayTypeToUpdate, setRelayTypeToUpdate] = React.useState(relayType);
    const [relayNotesToSend, setRelayNotesToSend] = React.useState("");
    const [relayNotesToUpdate, setRelayNotesToUpdate] = React.useState(
      relayNotes
    );

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
    const handleEdit = () => {
      setError("");
      setAlert("");
      setEdit(!edit);
    };
    const handleDelete = () => {
      setError("");
      setAlert("");

      AuthMiddleWare(navigate);
      const authToken = localStorage.getItem("AuthToken");
      axios.defaults.headers.common = { Authorization: `${authToken}` };

      axios
        .delete("/relays/" + documentId)
        .then((response) => {
          setAlert("Success: " + response.data.message);
          window.location.reload();
        })
        .catch((error) => {
          setError("Error: " + error.response.data.error);
        });
    };

    const onRelayIdToSendChangeHandler = (inputText) => {
      setRelayIdToSend(inputText.target.value);
    };
    const onRelayIdToUpdateChangeHandler = (inputText) => {
      setRelayIdToUpdate(inputText.target.value);
    };
    const onRelayMacAddressToSendChangeHandler = (inputText) => {
      setRelayMacAddressToSend(inputText.target.value);
    };
    const onRelayMacAddressToUpdateChangeHandler = (inputText) => {
      setRelayMacAddressToUpdate(inputText.target.value);
    };
    const onRelaySerialNumberToSendChangeHandler = (inputText) => {
      setRelaySerialNumberToSend(inputText.target.value);
    };
    const onRelaySerialNumberToUpdateChangeHandler = (inputText) => {
      setRelaySerialNumberToUpdate(inputText.target.value);
    };
    const onRelayTitleToSendChangeHandler = (inputText) => {
      setRelayTitleToSend(inputText.target.value);
    };
    const onRelayTitleToUpdateChangeHandler = (inputText) => {
      setRelayTitleToUpdate(inputText.target.value);
    };
    const onRelayStatusToSendChangeHandler = (inputText) => {
      setRelayStatusToSend(inputText.target.value);
    };
    const onRelayStatusToUpdateChangeHandler = (inputText) => {
      setRelayStatusToUpdate(inputText.target.value);
    };
    const onRelayTypeToSendChangeHandler = (inputText) => {
      setRelayTypeToSend(inputText.target.value);
    };
    const onRelayTypeToUpdateChangeHandler = (inputText) => {
      setRelayTypeToUpdate(inputText.target.value);
    };
    const onRelayNotesToSendChangeHandler = (inputText) => {
      setRelayNotesToSend(inputText.target.value);
    };
    const onRelayNotesToUpdateChangeHandler = (inputText) => {
      setRelayNotesToUpdate(inputText.target.value);
    };

    const handleSave = () => {
      setError("");
      setAlert("");

      AuthMiddleWare(navigate);
      const authToken = localStorage.getItem("AuthToken");
      axios.defaults.headers.common = { Authorization: `${authToken}` };

      const newRelay = {
        relayId: relayIdToSend,
        relayTitle: relayTitleToSend,
        relayStatus: relayStatusToSend,
        relayType: relayTypeToSend,
        relayMacAddress: relayMacAddressToSend,
        relaySerialNumber: relaySerialNumberToSend,
        relayNotes: relayNotesToSend,
      };

      axios
        .post("/relays", newRelay)
        .then((response) => {
          setRelayIdToSend("");
          setRelayTitleToSend("");
          setRelayStatusToSend("");
          setRelayTypeToSend("");
          setRelayMacAddressToSend("");
          setRelaySerialNumberToSend("");
          setRelayNotesToSend("");

          setAlert(
            "Relay " + response.data.documentId + " successfully created!"
          );
          window.location.reload();
        })
        .catch((error) => {
          setError("Error: " + error.response.data.error);
        });
    };

    const handleUpdate = () => {
      AuthMiddleWare(navigate);
      const authToken = localStorage.getItem("AuthToken");
      axios.defaults.headers.common = { Authorization: `${authToken}` };

      const currentRelay = {
        relayId: relayId,
        relayTitle: relayTitle,
        relayStatus: relayStatus,
        relayMacAddress: relayMacAddress,
        relaySerialNumber: relaySerialNumber,
        relayType: relayType,
        relayNotes: relayNotes,
      };

      const updateRelay = {
        relayId: relayIdToUpdate,
        relayTitle: relayTitleToUpdate,
        relayMacAddress: relayMacAddressToUpdate,
        relaySerialNumber: relaySerialNumberToUpdate,
        relayStatus: relayStatusToUpdate,
        relayType: relayTypeToUpdate,
        relayNotes: relayNotesToUpdate,
      };

      if (
        currentRelay.relayId === updateRelay.relayId &&
        currentRelay.relayTitle === updateRelay.relayTitle &&
        currentRelay.relayStatus === updateRelay.relayStatus &&
        currentRelay.relayType === updateRelay.relayType &&
        currentRelay.relayNotes === updateRelay.relayNotes &&
        currentRelay.relayMacAddress === updateRelay.relayMacAddress &&
        currentRelay.relaySerialNumber === updateRelay.relaySerialNumber
      ) {
        setError("Error: Please make a change before updating the relay");
      } else {
        axios
          .put("/relays/" + documentId, updateRelay)
          .then((response) => {
            setAlert("Success: " + response.data.message);
            window.location.reload();
          })
          .catch((error) => {
            setError("Error: " + error.response.data.error);
          });
      }

      setEdit(!edit);
    };

    return (
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* eslint-disable-next-line */}
              {new Array(relays).map((allRelays) => {
                if (allRelays.length !== undefined) {
                  return (
                    <React.Fragment key={Math.random()}>
                      {allRelays
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((relay) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={relay.documentId}
                              onClick={() => handleOpenPopup(relay.documentId)}
                            >
                              {columns.map((column) => {
                                const value = relay[column.id];
                                return (
                                  <TableCell key={column.id}>{value}</TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </React.Fragment>
                  );
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[1, 5, 10, 20, 30, 50, 100]}
          component="div"
          count={relays.length !== undefined ? relays.length : 1}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={openPopup || openNewRelay}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Relay Actions
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Grid container spacing={1} align="center" justify="center">
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                margin="normal"
                paddingTop={1}
                paddingBottom={1}
                spacing={2}
              ></Grid>
              <Divider variant="middle" />
              <Grid
                container
                paddingRight={5}
                paddingLeft={5}
                paddingTop={1}
                paddingBottom={10}
                spacing={2}
              >
                <Grid
                  container
                  paddingRight={5}
                  paddingLeft={5}
                  paddingTop={3}
                  paddingBottom={10}
                  spacing={2}
                >
                  <Grid item xs={12}>
                    {alert !== "" && (
                      <Alert margin="normal" severity="success">
                        {alert}
                      </Alert>
                    )}
                    {error !== "" && (
                      <Alert margin="normal" severity="error">
                        {error}
                      </Alert>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="relayId"
                      fullWidth
                      margin="normal"
                      id="relayId"
                      helperText="Relay ID"
                      type="number"
                      label={relayId !== "" ? relayIdToUpdate : relayIdToSend}
                      autoFocus
                      onChange={
                        documentId !== ""
                          ? onRelayIdToUpdateChangeHandler
                          : onRelayIdToSendChangeHandler
                      }
                      disabled={!edit && !openNewRelay}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      margin="normal"
                      id="relayTitle"
                      helperText="Relay Name"
                      label={
                        relayTitle !== ""
                          ? relayTitleToUpdate
                          : relayTitleToSend
                      }
                      name="relayTitle"
                      autoFocus
                      onChange={
                        documentId !== ""
                          ? onRelayTitleToUpdateChangeHandler
                          : onRelayTitleToSendChangeHandler
                      }
                      disabled={!edit && !openNewRelay}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="relayMacAddress"
                      fullWidth
                      margin="normal"
                      id="relayMacAddress"
                      helperText="Relay Mac Address"
                      label={
                        relayMacAddress !== ""
                          ? relayMacAddressToUpdate
                          : relayMacAddressToSend
                      }
                      autoFocus
                      onChange={
                        documentId !== ""
                          ? onRelayMacAddressToUpdateChangeHandler
                          : onRelayMacAddressToSendChangeHandler
                      }
                      disabled={!edit && !openNewRelay}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="relaySerialNumber"
                      fullWidth
                      margin="normal"
                      id="relaySerialNumber"
                      helperText="Relay Serial Number"
                      label={
                        relaySerialNumber !== ""
                          ? relaySerialNumberToUpdate
                          : relaySerialNumberToSend
                      }
                      autoFocus
                      onChange={
                        documentId !== ""
                          ? onRelaySerialNumberToUpdateChangeHandler
                          : onRelaySerialNumberToSendChangeHandler
                      }
                      disabled={!edit && !openNewRelay}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="documentId"
                      fullWidth
                      margin="normal"
                      id="documentId"
                      helperText="Relay Unique ID"
                      label={documentId}
                      autoFocus
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        label="Created Date"
                        defaultValue={dayjs(relayCreatedDate)}
                        disabled
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <Select
                        value={
                          relayType !== "" ? relayTypeToUpdate : relayTypeToSend
                        }
                        onChange={
                          documentId !== ""
                            ? onRelayTypeToUpdateChangeHandler
                            : onRelayTypeToSendChangeHandler
                        }
                        displayEmpty
                        disabled={!edit && !openNewRelay}
                        inputProps={{ "aria-label": "Type" }}
                      >
                        <MenuItem value="testing">
                          <em>Testing</em>
                        </MenuItem>
                        <MenuItem value="not tested">
                          <em>Not Tested</em>
                        </MenuItem>
                        <MenuItem value="verified">
                          <em>Verified</em>
                        </MenuItem>
                      </Select>
                      <FormHelperText>Type</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <Select
                        value={
                          relayStatus !== ""
                            ? relayStatusToUpdate
                            : relayStatusToSend
                        }
                        onChange={
                          documentId !== ""
                            ? onRelayStatusToUpdateChangeHandler
                            : onRelayStatusToSendChangeHandler
                        }
                        displayEmpty
                        disabled={(!edit && !openNewRelay) || !relayAvailable}
                        inputProps={{ "aria-label": "Status" }}
                      >
                        <MenuItem value="unknown">
                          <em>Unknown</em>
                        </MenuItem>
                        <MenuItem value="available">
                          <em>Available</em>
                        </MenuItem>
                        <MenuItem value="broken">
                          <em>Broken</em>
                        </MenuItem>
                        <MenuItem value="retired">
                          <em>Retired</em>
                        </MenuItem>
                        {!relayAvailable ? (
                          <MenuItem value="active">
                            <em>Active</em>
                          </MenuItem>
                        ) : (
                          <></>
                        )}
                      </Select>
                      <FormHelperText>Status</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="notes"
                      fullWidth
                      margin="normal"
                      id="notes"
                      helperText="Notes"
                      label={
                        relayNotes !== ""
                          ? relayNotesToUpdate
                          : relayNotesToSend
                      }
                      onChange={
                        documentId !== ""
                          ? onRelayNotesToUpdateChangeHandler
                          : onRelayNotesToSendChangeHandler
                      }
                      autoFocus
                      multiline
                      disabled={!edit && !openNewRelay}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {openNewRelay === false && userLevel === "admin" ? (
              <Button autoFocus onClick={handleDelete}>
                Delete
              </Button>
            ) : (
              <></>
            )}

            {openNewRelay === false &&
            (userLevel === "admin" || userLevel === "write") ? (
              edit === true ? (
                <Button autoFocus onClick={handleEdit}>
                  Preview
                </Button>
              ) : (
                <Button autoFocus onClick={handleEdit}>
                  Edit
                </Button>
              )
            ) : (
              <></>
            )}
            {documentId !== "" &&
            (userLevel === "admin" || userLevel === "write") ? (
              <Button autoFocus onClick={handleUpdate}>
                Update
              </Button>
            ) : (
              <></>
            )}
            {documentId === "" &&
            (userLevel === "admin" || userLevel === "write") ? (
              <Button autoFocus onClick={handleSave}>
                Save
              </Button>
            ) : (
              <></>
            )}
          </DialogActions>
        </BootstrapDialog>
      </Paper>
    );
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px",
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Relays
            </Typography>
            {userLevel !== "read" ? (
              <Typography
                component="h2"
                variant="h6"
                color="inherit"
                noWrap
                onClick={handleNewRelay}
              >
                Create Relay
              </Typography>
            ) : (
              <></>
            )}
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <ApplicationNavigation />
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid
              container
              spacing={1}
              alignItems="center"
              justifyContent="center"
            >
              <Card align="center" justify="center">
                <Box
                  component="form"
                  noValidate
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid
                    container
                    padding={5}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <TableViewOfData />
                  </Grid>
                </Box>
              </Card>
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
