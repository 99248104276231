import * as React from "react";
import { useNavigate } from "react-router-dom";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Alert from "@mui/material/Alert";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"; // Can be updated to the attached link once available: https://mui.com/x/react-date-pickers/date-time-range-picker/
import { ApplicationNavigation } from "../components/navigation";
import { AuthMiddleWare } from "../utils/authMiddleWare";

import dayjs from "dayjs";
import axios from "axios";

const drawerWidth = 300;
const defaultTheme = createTheme();

const columns = [
  { id: "basestationTitle", label: "Name", minWidth: 170 },
  { id: "basestationId", label: "ID", minWidth: 10 },
  {
    id: "basestationCreatedDate",
    label: "Created Date",
    minWidth: 170,
  },
  {
    id: "basestationMacAddress",
    label: "Mac Address",
    minWidth: 170,
  },
  {
    id: "basestationStatus",
    label: "Status",
    minWidth: 50,
  },
  {
    id: "basestationType",
    label: "Type",
    minWidth: 150,
  },
];
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(7),
      },
    }),
  },
}));

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://besic.org/">
        BESI-C
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Basestations() {
  const navigate = useNavigate();
  AuthMiddleWare(navigate);

  const [basestations, setBasestations] = React.useState({});
  const [userLevel, setUserLevel] = React.useState("");
  const [open, setOpen] = React.useState(true);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [openNewBasestation, setOpenNewBasestation] = React.useState(false);

  const [documentId, setDocumentId] = React.useState("");
  const [basestationId, setBasestationId] = React.useState("");
  const [basestationTitle, setBasestationTitle] = React.useState("");
  const [basestationCreatedDate, setBasestationCreatedDate] = React.useState(
    ""
  );
  const [basestationMacAddress, setBasestationMacAddress] = React.useState("");
  const [basestationStatus, setBasestationStatus] = React.useState("");
  const [basestationType, setBasestationType] = React.useState("");
  const [basestationNotes, setBasestationNotes] = React.useState("");

  const [basestationAvailable, setBasestationAvailable] = React.useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const handleOpen = () => {
    setOpenPopup(true);
  };

  const handleClose = () => {
    setDocumentId("");
    setBasestationId("");
    setBasestationTitle("");
    setBasestationCreatedDate("");
    setBasestationStatus("");
    setBasestationMacAddress("");
    setBasestationType("");
    setBasestationNotes("");

    setOpenPopup(false);
    setOpenNewBasestation(false);
  };

  const handleNewBasestation = () => {
    setDocumentId("");
    setBasestationCreatedDate(new Date().toISOString());
    setOpenNewBasestation(true);
  };

  const handleOpenPopup = (event) => {
    AuthMiddleWare(navigate);
    const authToken = localStorage.getItem("AuthToken");
    axios.defaults.headers.common = { Authorization: `${authToken}` };

    axios
      .get("/basestations/" + event)
      .then((response) => {
        setDocumentId(response.data.documentId);
        setBasestationId(response.data.basestationId);
        setBasestationTitle(response.data.basestationTitle);
        setBasestationCreatedDate(response.data.basestationCreatedDate);
        setBasestationMacAddress(response.data.basestationMacAddress);
        setBasestationStatus(response.data.basestationStatus);
        setBasestationType(response.data.basestationType);
        setBasestationNotes(response.data.basestationNotes);

        setBasestationAvailable(true);
        if (response.data.basestationStatus === "active") {
          setBasestationAvailable(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    handleOpen();
  };

  React.useEffect(() => {
    AuthMiddleWare(navigate);
    const authToken = localStorage.getItem("AuthToken");
    axios.defaults.headers.common = { Authorization: `${authToken}` };

    axios
      .get("/basestations")
      .then((response) => {
        setBasestations(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [navigate]);

  React.useEffect(() => {
    const getUserData = () => {
      AuthMiddleWare(navigate);
      const authToken = localStorage.getItem("AuthToken");
      axios.defaults.headers.common = { Authorization: `${authToken}` };

      axios
        .get("/user")
        .then((response) => {
          setUserLevel(response.data.userCredentials.level);
        })
        .catch((error) => {
          if (error.response.status === 403) {
            navigate("/signin");
          }
        });
    };

    getUserData();
  });

  function TableViewOfData() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [alert, setAlert] = React.useState("");
    const [error, setError] = React.useState("");
    const [edit, setEdit] = React.useState(false);

    const [basestationIdToSend, setBasestationIdToSend] = React.useState("");
    const [basestationIdToUpdate, setBasestationIdToUpdate] = React.useState(
      basestationId
    );
    const [basestationTitleToSend, setBasestationTitleToSend] = React.useState(
      ""
    );
    const [
      basestationTitleToUpdate,
      setBasestationTitleToUpdate,
    ] = React.useState(basestationTitle);
    const [
      basestationMacAddressToSend,
      setBasestationMacAddressToSend,
    ] = React.useState("");
    const [
      basestationMacAddressToUpdate,
      setBasestationMacAddressToUpdate,
    ] = React.useState(basestationTitle);
    const [
      basestationStatusToSend,
      setBasestationStatusToSend,
    ] = React.useState("available");
    const [
      basestationStatusToUpdate,
      setBasestationStatusToUpdate,
    ] = React.useState(basestationStatus);
    const [basestationTypeToSend, setBasestationTypeToSend] = React.useState(
      ""
    );
    const [
      basestationTypeToUpdate,
      setBasestationTypeToUpdate,
    ] = React.useState(basestationType);
    const [basestationNotesToSend, setBasestationNotesToSend] = React.useState(
      ""
    );
    const [
      basestationNotesToUpdate,
      setBasestationNotesToUpdate,
    ] = React.useState(basestationNotes);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
    const handleEdit = () => {
      setError("");
      setAlert("");
      setEdit(!edit);
    };
    const handleDelete = () => {
      setError("");
      setAlert("");

      AuthMiddleWare(navigate);
      const authToken = localStorage.getItem("AuthToken");
      axios.defaults.headers.common = { Authorization: `${authToken}` };

      axios
        .delete("/basestations/" + documentId)
        .then((response) => {
          setAlert("Success: " + response.data.message);
          window.location.reload();
        })
        .catch((error) => {
          setError("Error: " + error.response.data.error);
        });
    };

    const onBasestationIdToSendChangeHandler = (inputText) => {
      setBasestationIdToSend(inputText.target.value);
    };
    const onBasestationIdToUpdateChangeHandler = (inputText) => {
      setBasestationIdToUpdate(inputText.target.value);
    };
    const onBasestationMacAddressToSendChangeHandler = (inputText) => {
      setBasestationMacAddressToSend(inputText.target.value);
    };
    const onBasestationMacAddressToUpdateChangeHandler = (inputText) => {
      setBasestationMacAddressToUpdate(inputText.target.value);
    };
    const onBasestationTitleToSendChangeHandler = (inputText) => {
      setBasestationTitleToSend(inputText.target.value);
    };
    const onBasestationTitleToUpdateChangeHandler = (inputText) => {
      setBasestationTitleToUpdate(inputText.target.value);
    };
    const onBasestationStatusToSendChangeHandler = (inputText) => {
      setBasestationStatusToSend(inputText.target.value);
    };
    const onBasestationStatusToUpdateChangeHandler = (inputText) => {
      setBasestationStatusToUpdate(inputText.target.value);
    };
    const onBasestationTypeToSendChangeHandler = (inputText) => {
      setBasestationTypeToSend(inputText.target.value);
    };
    const onBasestationTypeToUpdateChangeHandler = (inputText) => {
      setBasestationTypeToUpdate(inputText.target.value);
    };
    const onBasestationNotesToSendChangeHandler = (inputText) => {
      setBasestationNotesToSend(inputText.target.value);
    };
    const onBasestationNotesToUpdateChangeHandler = (inputText) => {
      setBasestationNotesToUpdate(inputText.target.value);
    };

    const handleSave = () => {
      setError("");
      setAlert("");

      AuthMiddleWare(navigate);
      const authToken = localStorage.getItem("AuthToken");
      axios.defaults.headers.common = { Authorization: `${authToken}` };

      const newBasestation = {
        basestationId: basestationIdToSend,
        basestationTitle: basestationTitleToSend,
        basestationStatus: basestationStatusToSend,
        basestationType: basestationTypeToSend,
        basestationMacAddress: basestationMacAddressToSend,
        basestationNotes: basestationNotesToSend,
      };

      axios
        .post("/basestations", newBasestation)
        .then((response) => {
          setBasestationIdToSend("");
          setBasestationTitleToSend("");
          setBasestationStatusToSend("");
          setBasestationTypeToSend("");
          setBasestationMacAddressToSend("");
          setBasestationNotesToSend("");

          setAlert(
            "Basestation " + response.data.documentId + " successfully created!"
          );
          window.location.reload();
        })
        .catch((error) => {
          setError("Error: " + error.response.data.error);
        });
    };

    const handleUpdate = () => {
      AuthMiddleWare(navigate);
      const authToken = localStorage.getItem("AuthToken");
      axios.defaults.headers.common = { Authorization: `${authToken}` };

      const currentBasestation = {
        basestationId: basestationId,
        basestationTitle: basestationTitle,
        basestationStatus: basestationStatus,
        basestationMacAddress: basestationMacAddress,
        basestationType: basestationType,
        basestationNotes: basestationNotes,
      };

      const updateBasestation = {
        basestationId: basestationIdToUpdate,
        basestationTitle: basestationTitleToUpdate,
        basestationMacAddress: basestationMacAddressToUpdate,
        basestationStatus: basestationStatusToUpdate,
        basestationType: basestationTypeToUpdate,
        basestationNotes: basestationNotesToUpdate,
      };

      if (
        currentBasestation.basestationId === updateBasestation.basestationId &&
        currentBasestation.basestationTitle ===
          updateBasestation.basestationTitle &&
        currentBasestation.basestationStatus ===
          updateBasestation.basestationStatus &&
        currentBasestation.basestationType ===
          updateBasestation.basestationType &&
        currentBasestation.basestationNotes ===
          updateBasestation.basestationNotes &&
        currentBasestation.basestationMacAddress ===
          updateBasestation.basestationMacAddress
      ) {
        setError("Error: Please make a change before updating the basestation");
      } else {
        axios
          .put("/basestations/" + documentId, updateBasestation)
          .then((response) => {
            setAlert("Success: " + response.data.message);
            window.location.reload();
          })
          .catch((error) => {
            setError("Error: " + error.response.data.error);
          });
      }

      setEdit(!edit);
    };

    return (
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* eslint-disable-next-line */}
              {new Array(basestations).map((allBasestations) => {
                if (allBasestations.length !== undefined) {
                  return (
                    <React.Fragment key={Math.random()}>
                      {allBasestations
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((basestation) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={basestation.documentId}
                              onClick={() =>
                                handleOpenPopup(basestation.documentId)
                              }
                            >
                              {columns.map((column) => {
                                const value = basestation[column.id];
                                return (
                                  <TableCell key={column.id}>{value}</TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </React.Fragment>
                  );
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[1, 5, 10, 20, 30, 50, 100]}
          component="div"
          count={basestations.length !== undefined ? basestations.length : 1}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={openPopup || openNewBasestation}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Basestation Actions
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Grid container spacing={1} align="center" justify="center">
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                margin="normal"
                paddingTop={1}
                paddingBottom={1}
                spacing={2}
              ></Grid>
              <Divider variant="middle" />
              <Grid
                container
                paddingRight={5}
                paddingLeft={5}
                paddingTop={1}
                paddingBottom={10}
                spacing={2}
              >
                <Grid
                  container
                  paddingRight={5}
                  paddingLeft={5}
                  paddingTop={3}
                  paddingBottom={10}
                  spacing={2}
                >
                  <Grid item xs={12}>
                    {alert !== "" && (
                      <Alert margin="normal" severity="success">
                        {alert}
                      </Alert>
                    )}
                    {error !== "" && (
                      <Alert margin="normal" severity="error">
                        {error}
                      </Alert>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="basestationId"
                      fullWidth
                      margin="normal"
                      id="basestationId"
                      helperText="Basestation ID"
                      type="number"
                      label={
                        basestationId !== ""
                          ? basestationIdToUpdate
                          : basestationIdToSend
                      }
                      autoFocus
                      onChange={
                        documentId !== ""
                          ? onBasestationIdToUpdateChangeHandler
                          : onBasestationIdToSendChangeHandler
                      }
                      disabled={!edit && !openNewBasestation}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      margin="normal"
                      id="basestationTitle"
                      helperText="Basestation Name"
                      label={
                        basestationTitle !== ""
                          ? basestationTitleToUpdate
                          : basestationTitleToSend
                      }
                      name="basestationTitle"
                      autoFocus
                      onChange={
                        documentId !== ""
                          ? onBasestationTitleToUpdateChangeHandler
                          : onBasestationTitleToSendChangeHandler
                      }
                      disabled={!edit && !openNewBasestation}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="basestationMacAddress"
                      fullWidth
                      margin="normal"
                      id="basestationMacAddress"
                      helperText="Basestation Mac Address"
                      label={
                        basestationMacAddress !== ""
                          ? basestationMacAddressToUpdate
                          : basestationMacAddressToSend
                      }
                      autoFocus
                      onChange={
                        documentId !== ""
                          ? onBasestationMacAddressToUpdateChangeHandler
                          : onBasestationMacAddressToSendChangeHandler
                      }
                      disabled={!edit && !openNewBasestation}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="documentId"
                      fullWidth
                      margin="normal"
                      id="documentId"
                      helperText="Basestation Unique ID"
                      label={documentId}
                      autoFocus
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        label="Created Date"
                        defaultValue={dayjs(basestationCreatedDate)}
                        disabled
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <Select
                        value={
                          basestationType !== ""
                            ? basestationTypeToUpdate
                            : basestationTypeToSend
                        }
                        onChange={
                          documentId !== ""
                            ? onBasestationTypeToUpdateChangeHandler
                            : onBasestationTypeToSendChangeHandler
                        }
                        displayEmpty
                        disabled={!edit && !openNewBasestation}
                        inputProps={{ "aria-label": "Type" }}
                      >
                        <MenuItem value="testing">
                          <em>Testing</em>
                        </MenuItem>
                        <MenuItem value="not tested">
                          <em>Not Tested</em>
                        </MenuItem>
                        <MenuItem value="verified">
                          <em>Verified</em>
                        </MenuItem>
                      </Select>
                      <FormHelperText>Type</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <Select
                        value={
                          basestationStatus !== ""
                            ? basestationStatusToUpdate
                            : basestationStatusToSend
                        }
                        onChange={
                          documentId !== ""
                            ? onBasestationStatusToUpdateChangeHandler
                            : onBasestationStatusToSendChangeHandler
                        }
                        displayEmpty
                        disabled={
                          (!edit && !openNewBasestation) ||
                          !basestationAvailable
                        }
                        inputProps={{ "aria-label": "Status" }}
                      >
                        <MenuItem value="unknown">
                          <em>Unknown</em>
                        </MenuItem>
                        <MenuItem value="available">
                          <em>Available</em>
                        </MenuItem>
                        <MenuItem value="broken">
                          <em>Broken</em>
                        </MenuItem>
                        <MenuItem value="retired">
                          <em>Retired</em>
                        </MenuItem>
                        {!basestationAvailable ? (
                          <MenuItem value="active">
                            <em>Active</em>
                          </MenuItem>
                        ) : (
                          <></>
                        )}
                      </Select>
                      <FormHelperText>Status</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="notes"
                      fullWidth
                      margin="normal"
                      id="notes"
                      helperText="Notes"
                      label={
                        basestationNotes !== ""
                          ? basestationNotesToUpdate
                          : basestationNotesToSend
                      }
                      onChange={
                        documentId !== ""
                          ? onBasestationNotesToUpdateChangeHandler
                          : onBasestationNotesToSendChangeHandler
                      }
                      autoFocus
                      multiline
                      disabled={!edit && !openNewBasestation}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {openNewBasestation === false && userLevel === "admin" ? (
              <Button autoFocus onClick={handleDelete}>
                Delete
              </Button>
            ) : (
              <></>
            )}

            {openNewBasestation === false &&
            (userLevel === "admin" || userLevel === "write") ? (
              edit === true ? (
                <Button autoFocus onClick={handleEdit}>
                  Preview
                </Button>
              ) : (
                <Button autoFocus onClick={handleEdit}>
                  Edit
                </Button>
              )
            ) : (
              <></>
            )}
            {documentId !== "" &&
            (userLevel === "admin" || userLevel === "write") ? (
              <Button autoFocus onClick={handleUpdate}>
                Update
              </Button>
            ) : (
              <></>
            )}
            {documentId === "" &&
            (userLevel === "admin" || userLevel === "write") ? (
              <Button autoFocus onClick={handleSave}>
                Save
              </Button>
            ) : (
              <></>
            )}
          </DialogActions>
        </BootstrapDialog>
      </Paper>
    );
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px",
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Basestations
            </Typography>
            {userLevel !== "read" ? (
              <Typography
                component="h2"
                variant="h6"
                color="inherit"
                noWrap
                onClick={handleNewBasestation}
              >
                Create Basestation
              </Typography>
            ) : (
              <></>
            )}
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <ApplicationNavigation />
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid
              container
              spacing={1}
              alignItems="center"
              justifyContent="center"
            >
              <Card align="center" justify="center">
                <Box
                  component="form"
                  noValidate
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid
                    container
                    padding={5}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <TableViewOfData />
                  </Grid>
                </Box>
              </Card>
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
