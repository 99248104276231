import * as React from "react";
import { useNavigate } from "react-router-dom";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Alert from "@mui/material/Alert";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"; // Can be updated to the attached link once available: https://mui.com/x/react-date-pickers/date-time-range-picker/
import { ApplicationNavigation } from "../components/navigation";
import { AuthMiddleWare } from "../utils/authMiddleWare";

import dayjs from "dayjs";
import axios from "axios";

const drawerWidth = 300;
const defaultTheme = createTheme();

const columns = [
  { id: "hotspotTitle", label: "Name", minWidth: 170 },
  { id: "hotspotId", label: "ID", minWidth: 10 },
  {
    id: "hotspotMacAddress",
    label: "Mac Address",
    minWidth: 170,
  },
  {
    id: "hotspotAccountNumber",
    label: "Account Number",
    minWidth: 170,
  },
  {
    id: "hotspotImei",
    label: "IMEI",
    minWidth: 170,
  },
  {
    id: "hotspotCarrier",
    label: "Carrier",
    minWidth: 50,
  },
  {
    id: "hotspotStatus",
    label: "Status",
    minWidth: 50,
  },
  {
    id: "hotspotType",
    label: "Type",
    minWidth: 150,
  },
];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(7),
      },
    }),
  },
}));

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://besic.org/">
        BESI-C
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Hotspots() {
  const navigate = useNavigate();
  AuthMiddleWare(navigate);

  const [hotspots, setHotspots] = React.useState({});
  const [userLevel, setUserLevel] = React.useState("");
  const [open, setOpen] = React.useState(true);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [openNewHotspot, setOpenNewHotspot] = React.useState(false);
  const [hotspotAvailable, setHotspotAvailable] = React.useState(false);

  const [documentId, setDocumentId] = React.useState("");
  const [hotspotId, setHotspotId] = React.useState("");
  const [hotspotTitle, setHotspotTitle] = React.useState("");
  const [hotspotCreatedDate, setHotspotCreatedDate] = React.useState("");
  const [hotspotMacAddress, setHotspotMacAddress] = React.useState("");
  const [hotspotStatus, setHotspotStatus] = React.useState("");
  const [hotspotType, setHotspotType] = React.useState("");
  const [hotspotImei, setHotspotImei] = React.useState("");
  const [hotspotAccountNumber, setHotspotAccountNumber] = React.useState("");
  const [hotspotCarrier, setHotspotCarrier] = React.useState("");
  const [hotspotNotes, setHotspotNotes] = React.useState("");

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const handleOpen = () => {
    setOpenPopup(true);
  };

  const handleClose = () => {
    setDocumentId("");
    setHotspotId("");
    setHotspotTitle("");
    setHotspotCreatedDate("");
    setHotspotStatus("");
    setHotspotMacAddress("");
    setHotspotType("");
    setHotspotNotes("");
    setHotspotImei("");
    setHotspotAccountNumber("");
    setHotspotCarrier("");

    setOpenPopup(false);
    setOpenNewHotspot(false);
  };

  const handleNewHotspot = () => {
    setDocumentId("");
    setHotspotCreatedDate(new Date().toISOString());
    setOpenNewHotspot(true);
  };

  const handleOpenPopup = (event) => {
    AuthMiddleWare(navigate);
    const authToken = localStorage.getItem("AuthToken");
    axios.defaults.headers.common = { Authorization: `${authToken}` };

    axios
      .get("/hotspots/" + event)
      .then((response) => {
        setDocumentId(response.data.documentId);
        setHotspotId(response.data.hotspotId);
        setHotspotTitle(response.data.hotspotTitle);
        setHotspotCreatedDate(response.data.hotspotCreatedDate);
        setHotspotMacAddress(response.data.hotspotMacAddress);
        setHotspotStatus(response.data.hotspotStatus);
        setHotspotType(response.data.hotspotType);
        setHotspotNotes(response.data.hotspotNotes);
        setHotspotImei(response.data.hotspotImei);
        setHotspotAccountNumber(response.data.hotspotAccountNumber);
        setHotspotCarrier(response.data.hotspotCarrier);

        setHotspotAvailable(true);
        if (response.data.hotspotStatus === "active") {
          setHotspotAvailable(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    handleOpen();
  };

  React.useEffect(() => {
    AuthMiddleWare(navigate);
    const authToken = localStorage.getItem("AuthToken");
    axios.defaults.headers.common = { Authorization: `${authToken}` };

    axios
      .get("/hotspots")
      .then((response) => {
        setHotspots(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [navigate]);

  React.useEffect(() => {
    const getUserData = () => {
      AuthMiddleWare(navigate);
      const authToken = localStorage.getItem("AuthToken");
      axios.defaults.headers.common = { Authorization: `${authToken}` };

      axios
        .get("/user")
        .then((response) => {
          setUserLevel(response.data.userCredentials.level);
        })
        .catch((error) => {
          if (error.response.status === 403) {
            navigate("/signin");
          }
        });
    };

    getUserData();
  });

  function TableViewOfData() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [alert, setAlert] = React.useState("");
    const [error, setError] = React.useState("");
    const [edit, setEdit] = React.useState(false);

    const [hotspotIdToSend, setHotspotIdToSend] = React.useState("");
    const [hotspotIdToUpdate, setHotspotIdToUpdate] = React.useState(hotspotId);
    const [hotspotTitleToSend, setHotspotTitleToSend] = React.useState("");
    const [hotspotTitleToUpdate, setHotspotTitleToUpdate] = React.useState(
      hotspotTitle
    );
    const [
      hotspotMacAddressToSend,
      setHotspotMacAddressToSend,
    ] = React.useState("");
    const [
      hotspotMacAddressToUpdate,
      setHotspotMacAddressToUpdate,
    ] = React.useState(hotspotMacAddress);
    const [hotspotStatusToSend, setHotspotStatusToSend] = React.useState(
      "available"
    );
    const [hotspotStatusToUpdate, setHotspotStatusToUpdate] = React.useState(
      hotspotStatus
    );
    const [hotspotTypeToSend, setHotspotTypeToSend] = React.useState("");
    const [hotspotTypeToUpdate, setHotspotTypeToUpdate] = React.useState(
      hotspotType
    );
    const [hotspotNotesToSend, setHotspotNotesToSend] = React.useState("");
    const [hotspotNotesToUpdate, setHotspotNotesToUpdate] = React.useState(
      hotspotNotes
    );
    const [hotspotImeiToSend, setHotspotImeiToSend] = React.useState("");
    const [hotspotImeiToUpdate, setHotspotImeiToUpdate] = React.useState(
      hotspotImei
    );
    const [
      hotspotAccountNumberToSend,
      setHotspotAccountNumberToSend,
    ] = React.useState("");
    const [
      hotspotAccountNumberToUpdate,
      setHotspotAccountNumberToUpdate,
    ] = React.useState(hotspotAccountNumber);
    const [hotspotCarrierToSend, setHotspotCarrierToSend] = React.useState("");
    const [hotspotCarrierToUpdate, setHotspotCarrierToUpdate] = React.useState(
      hotspotCarrier
    );

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
    const handleEdit = () => {
      setError("");
      setAlert("");
      setEdit(!edit);
    };
    const handleDelete = () => {
      setError("");
      setAlert("");

      AuthMiddleWare(navigate);
      const authToken = localStorage.getItem("AuthToken");
      axios.defaults.headers.common = { Authorization: `${authToken}` };

      axios
        .delete("/hotspots/" + documentId)
        .then((response) => {
          setAlert("Success: " + response.data.message);
          window.location.reload();
        })
        .catch((error) => {
          setError("Error: " + error.response.data.error);
        });
    };

    const onHotspotIdToSendChangeHandler = (inputText) => {
      setHotspotIdToSend(inputText.target.value);
    };
    const onHotspotIdToUpdateChangeHandler = (inputText) => {
      setHotspotIdToUpdate(inputText.target.value);
    };
    const onHotspotMacAddressToSendChangeHandler = (inputText) => {
      setHotspotMacAddressToSend(inputText.target.value);
    };
    const onHotspotMacAddressToUpdateChangeHandler = (inputText) => {
      setHotspotMacAddressToUpdate(inputText.target.value);
    };
    const onHotspotTitleToSendChangeHandler = (inputText) => {
      setHotspotTitleToSend(inputText.target.value);
    };
    const onHotspotTitleToUpdateChangeHandler = (inputText) => {
      setHotspotTitleToUpdate(inputText.target.value);
    };
    const onHotspotStatusToSendChangeHandler = (inputText) => {
      setHotspotStatusToSend(inputText.target.value);
    };
    const onHotspotStatusToUpdateChangeHandler = (inputText) => {
      setHotspotStatusToUpdate(inputText.target.value);
    };
    const onHotspotTypeToSendChangeHandler = (inputText) => {
      setHotspotTypeToSend(inputText.target.value);
    };
    const onHotspotTypeToUpdateChangeHandler = (inputText) => {
      setHotspotTypeToUpdate(inputText.target.value);
    };
    const onHotspotNotesToSendChangeHandler = (inputText) => {
      setHotspotNotesToSend(inputText.target.value);
    };
    const onHotspotNotesToUpdateChangeHandler = (inputText) => {
      setHotspotNotesToUpdate(inputText.target.value);
    };
    const onHotspotImeiToSendChangeHandler = (inputText) => {
      setHotspotImeiToSend(inputText.target.value);
    };
    const onHotspotImeiToUpdateChangeHandler = (inputText) => {
      setHotspotImeiToUpdate(inputText.target.value);
    };
    const onHotspotCarrierToSendChangeHandler = (inputText) => {
      setHotspotCarrierToSend(inputText.target.value);
    };
    const onHotspotCarrierToUpdateChangeHandler = (inputText) => {
      setHotspotCarrierToUpdate(inputText.target.value);
    };
    const onHotspotAccountNumberToSendChangeHandler = (inputText) => {
      setHotspotAccountNumberToSend(inputText.target.value);
    };
    const onHotspotAccountNumberToUpdateChangeHandler = (inputText) => {
      setHotspotAccountNumberToUpdate(inputText.target.value);
    };

    const handleSave = () => {
      setError("");
      setAlert("");

      AuthMiddleWare(navigate);
      const authToken = localStorage.getItem("AuthToken");
      axios.defaults.headers.common = { Authorization: `${authToken}` };

      const newHotspot = {
        hotspotId: hotspotIdToSend,
        hotspotTitle: hotspotTitleToSend,
        hotspotStatus: hotspotStatusToSend,
        hotspotType: hotspotTypeToSend,
        hotspotMacAddress: hotspotMacAddressToSend,
        hotspotNotes: hotspotNotesToSend,
        hotspotImei: hotspotImeiToSend,
        hotspotCarrier: hotspotCarrierToSend,
        hotspotAccountNumber: hotspotAccountNumberToSend,
      };

      axios
        .post("/hotspots", newHotspot)
        .then((response) => {
          setHotspotIdToSend("");
          setHotspotTitleToSend("");
          setHotspotStatusToSend("");
          setHotspotTypeToSend("");
          setHotspotMacAddressToSend("");
          setHotspotNotesToSend("");
          setHotspotAccountNumberToSend("");
          setHotspotCarrierToSend("");
          setHotspotImeiToSend("");

          setAlert(
            "Hotspot " + response.data.documentId + " successfully created!"
          );
          window.location.reload();
        })
        .catch((error) => {
          setError("Error: " + error.response.data.error);
        });
    };

    const handleUpdate = () => {
      AuthMiddleWare(navigate);
      const authToken = localStorage.getItem("AuthToken");
      axios.defaults.headers.common = { Authorization: `${authToken}` };

      const currentHotspot = {
        hotspotId: hotspotId,
        hotspotTitle: hotspotTitle,
        hotspotStatus: hotspotStatus,
        hotspotMacAddress: hotspotMacAddress,
        hotspotType: hotspotType,
        hotspotNotes: hotspotNotes,
        hotspotImei: hotspotImei,
        hotspotCarrier: hotspotCarrier,
        hotspotAccountNumber: hotspotAccountNumber,
      };

      const updateHotspot = {
        hotspotId: hotspotIdToUpdate,
        hotspotTitle: hotspotTitleToUpdate,
        hotspotMacAddress: hotspotMacAddressToUpdate,
        hotspotStatus: hotspotStatusToUpdate,
        hotspotType: hotspotTypeToUpdate,
        hotspotNotes: hotspotNotesToUpdate,
        hotspotImei: hotspotImeiToUpdate,
        hotspotCarrier: hotspotCarrierToUpdate,
        hotspotAccountNumber: hotspotAccountNumberToUpdate,
      };

      if (
        currentHotspot.hotspotId === updateHotspot.hotspotId &&
        currentHotspot.hotspotTitle === updateHotspot.hotspotTitle &&
        currentHotspot.hotspotStatus === updateHotspot.hotspotStatus &&
        currentHotspot.hotspotType === updateHotspot.hotspotType &&
        currentHotspot.hotspotNotes === updateHotspot.hotspotNotes &&
        currentHotspot.hotspotMacAddress === updateHotspot.hotspotMacAddress &&
        currentHotspot.hotspotImei === updateHotspot.hotspotImei &&
        currentHotspot.hotspotCarrier === updateHotspot.hotspotCarrier &&
        currentHotspot.hotspotAccountNumber ===
          updateHotspot.hotspotAccountNumber
      ) {
        setError("Error: Please make a change before updating the hotspot");
      } else {
        axios
          .put("/hotspots/" + documentId, updateHotspot)
          .then((response) => {
            setAlert("Success: " + response.data.message);
            window.location.reload();
          })
          .catch((error) => {
            setError("Error: " + error.response.data.error);
          });
      }

      setEdit(!edit);
    };

    return (
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* eslint-disable-next-line */}
              {new Array(hotspots).map((allHotspots) => {
                if (allHotspots.length !== undefined) {
                  return (
                    <React.Fragment key={Math.random()}>
                      {allHotspots
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((hotspot) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={hotspot.documentId}
                              onClick={() =>
                                handleOpenPopup(hotspot.documentId)
                              }
                            >
                              {columns.map((column) => {
                                const value = hotspot[column.id];
                                return (
                                  <TableCell key={column.id}>{value}</TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </React.Fragment>
                  );
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[1, 5, 10, 20, 30, 50, 100]}
          component="div"
          count={hotspots.length !== undefined ? hotspots.length : 1}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={openPopup || openNewHotspot}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Hotspot Actions
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Grid container spacing={1} align="center" justify="center">
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                margin="normal"
                paddingTop={1}
                paddingBottom={1}
                spacing={2}
              ></Grid>
              <Divider variant="middle" />
              <Grid
                container
                paddingRight={5}
                paddingLeft={5}
                paddingTop={1}
                paddingBottom={10}
                spacing={2}
              >
                <Grid
                  container
                  paddingRight={5}
                  paddingLeft={5}
                  paddingTop={3}
                  paddingBottom={10}
                  spacing={2}
                >
                  <Grid item xs={12}>
                    {alert !== "" && (
                      <Alert margin="normal" severity="success">
                        {alert}
                      </Alert>
                    )}
                    {error !== "" && (
                      <Alert margin="normal" severity="error">
                        {error}
                      </Alert>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="hotspotId"
                      fullWidth
                      margin="normal"
                      id="hotspotId"
                      helperText="Hotspot ID"
                      type="number"
                      label={
                        hotspotId !== "" ? hotspotIdToUpdate : hotspotIdToSend
                      }
                      autoFocus
                      onChange={
                        documentId !== ""
                          ? onHotspotIdToUpdateChangeHandler
                          : onHotspotIdToSendChangeHandler
                      }
                      disabled={!edit && !openNewHotspot}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      margin="normal"
                      id="hotspotTitle"
                      helperText="Hotspot Name"
                      label={
                        hotspotTitle !== ""
                          ? hotspotTitleToUpdate
                          : hotspotTitleToSend
                      }
                      name="hotspotTitle"
                      autoFocus
                      onChange={
                        documentId !== ""
                          ? onHotspotTitleToUpdateChangeHandler
                          : onHotspotTitleToSendChangeHandler
                      }
                      disabled={!edit && !openNewHotspot}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="hotspotMacAddress"
                      fullWidth
                      margin="normal"
                      id="hotspotMacAddress"
                      helperText="Hotspot Mac Address"
                      label={
                        hotspotMacAddress !== ""
                          ? hotspotMacAddressToUpdate
                          : hotspotMacAddressToSend
                      }
                      autoFocus
                      onChange={
                        documentId !== ""
                          ? onHotspotMacAddressToUpdateChangeHandler
                          : onHotspotMacAddressToSendChangeHandler
                      }
                      disabled={!edit && !openNewHotspot}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="hotspotImei"
                      fullWidth
                      margin="normal"
                      id="hotspotImei"
                      helperText="Hotspot IMEI"
                      type="number"
                      label={
                        hotspotImei !== ""
                          ? hotspotImeiToUpdate
                          : hotspotImeiToSend
                      }
                      autoFocus
                      onChange={
                        documentId !== ""
                          ? onHotspotImeiToUpdateChangeHandler
                          : onHotspotImeiToSendChangeHandler
                      }
                      disabled={!edit && !openNewHotspot}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="hotspotAccountNumber"
                      fullWidth
                      margin="normal"
                      id="hotspotAccountNumber"
                      helperText="Hotspot Account Number"
                      type="number"
                      label={
                        hotspotAccountNumber !== ""
                          ? hotspotAccountNumberToUpdate
                          : hotspotAccountNumberToSend
                      }
                      autoFocus
                      onChange={
                        documentId !== ""
                          ? onHotspotAccountNumberToUpdateChangeHandler
                          : onHotspotAccountNumberToSendChangeHandler
                      }
                      disabled={!edit && !openNewHotspot}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="documentId"
                      fullWidth
                      margin="normal"
                      id="documentId"
                      helperText="Hotspot Unique ID"
                      label={documentId}
                      autoFocus
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        label="Created Date"
                        defaultValue={dayjs(hotspotCreatedDate)}
                        disabled
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <Select
                        value={
                          hotspotType !== ""
                            ? hotspotTypeToUpdate
                            : hotspotTypeToSend
                        }
                        onChange={
                          documentId !== ""
                            ? onHotspotTypeToUpdateChangeHandler
                            : onHotspotTypeToSendChangeHandler
                        }
                        displayEmpty
                        disabled={!edit && !openNewHotspot}
                        inputProps={{ "aria-label": "Type" }}
                      >
                        <MenuItem value="testing">
                          <em>Testing</em>
                        </MenuItem>
                        <MenuItem value="not tested">
                          <em>Not Tested</em>
                        </MenuItem>
                        <MenuItem value="verified">
                          <em>Verified</em>
                        </MenuItem>
                      </Select>
                      <FormHelperText>Type</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <Select
                        value={
                          hotspotStatus !== ""
                            ? hotspotStatusToUpdate
                            : hotspotStatusToSend
                        }
                        onChange={
                          documentId !== ""
                            ? onHotspotStatusToUpdateChangeHandler
                            : onHotspotStatusToSendChangeHandler
                        }
                        displayEmpty
                        disabled={
                          (!edit && !openNewHotspot) || !hotspotAvailable
                        }
                        inputProps={{ "aria-label": "Status" }}
                      >
                        <MenuItem value="unknown">
                          <em>Unknown</em>
                        </MenuItem>
                        <MenuItem value="available">
                          <em>Available</em>
                        </MenuItem>
                        <MenuItem value="broken">
                          <em>Broken</em>
                        </MenuItem>
                        <MenuItem value="retired">
                          <em>Retired</em>
                        </MenuItem>
                        {!hotspotAvailable ? (
                          <MenuItem value="active">
                            <em>Active</em>
                          </MenuItem>
                        ) : (
                          <></>
                        )}
                      </Select>
                      <FormHelperText>Status</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <Select
                        value={
                          hotspotCarrier !== ""
                            ? hotspotCarrierToUpdate
                            : hotspotCarrierToSend
                        }
                        onChange={
                          documentId !== ""
                            ? onHotspotCarrierToUpdateChangeHandler
                            : onHotspotCarrierToSendChangeHandler
                        }
                        displayEmpty
                        fullwidth
                        disabled={!edit && !openNewHotspot}
                        inputProps={{ "aria-label": "Carrier" }}
                      >
                        <MenuItem value="none">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="verizon">
                          <em>Verizon</em>
                        </MenuItem>
                        <MenuItem value="at&t">
                          <em>AT&T</em>
                        </MenuItem>
                        <MenuItem value="t-mobile">
                          <em>T-Mobile</em>
                        </MenuItem>
                      </Select>
                      <FormHelperText>Carrier</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="notes"
                      fullWidth
                      margin="normal"
                      id="notes"
                      helperText="Notes"
                      label={
                        hotspotNotes !== ""
                          ? hotspotNotesToUpdate
                          : hotspotNotesToSend
                      }
                      onChange={
                        documentId !== ""
                          ? onHotspotNotesToUpdateChangeHandler
                          : onHotspotNotesToSendChangeHandler
                      }
                      autoFocus
                      multiline
                      disabled={!edit && !openNewHotspot}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {openNewHotspot === false && userLevel === "admin" ? (
              <Button autoFocus onClick={handleDelete}>
                Delete
              </Button>
            ) : (
              <></>
            )}

            {openNewHotspot === false &&
            (userLevel === "admin" || userLevel === "write") ? (
              edit === true ? (
                <Button autoFocus onClick={handleEdit}>
                  Preview
                </Button>
              ) : (
                <Button autoFocus onClick={handleEdit}>
                  Edit
                </Button>
              )
            ) : (
              <></>
            )}
            {documentId !== "" &&
            (userLevel === "admin" || userLevel === "write") ? (
              <Button autoFocus onClick={handleUpdate}>
                Update
              </Button>
            ) : (
              <></>
            )}
            {documentId === "" &&
            (userLevel === "admin" || userLevel === "write") ? (
              <Button autoFocus onClick={handleSave}>
                Save
              </Button>
            ) : (
              <></>
            )}
          </DialogActions>
        </BootstrapDialog>
      </Paper>
    );
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px",
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Hotspots
            </Typography>
            {userLevel !== "read" ? (
              <Typography
                component="h2"
                variant="h6"
                color="inherit"
                noWrap
                onClick={handleNewHotspot}
              >
                Create Hotspot
              </Typography>
            ) : (
              <></>
            )}
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <ApplicationNavigation />
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid
              container
              spacing={1}
              alignItems="center"
              justifyContent="center"
            >
              <Card align="center" justify="center">
                <Box
                  component="form"
                  noValidate
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid
                    container
                    padding={5}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <TableViewOfData />
                  </Grid>
                </Box>
              </Card>
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
