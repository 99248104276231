import * as React from "react";
import { useNavigate } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
// import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import PersonIcon from "@mui/icons-material/Person";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import WatchIcon from "@mui/icons-material/Watch";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import CellTowerIcon from "@mui/icons-material/CellTower";
import SensorsIcon from "@mui/icons-material/Sensors";
import RouterIcon from "@mui/icons-material/Router";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import PostAddIcon from "@mui/icons-material/PostAdd";

export function ApplicationNavigation() {
  const navigate = useNavigate();

  // const HomepageHandler = () => {
  //   navigate("/homepage");
  // };

  const HeartBeatsHandler = () => {
    navigate("/heartbeats");
  };

  function DeploymentsHandler() {
    navigate("/deployments");
  }

  const BoxesHandler = () => {
    navigate("/boxes");
  };

  const BasestationHandler = () => {
    navigate("/basestations");
  };

  function RelaysHandler() {
    navigate("/relays");
  }

  const WatchesHandler = () => {
    navigate("/watches");
  };

  const HotspotsHandler = () => {
    navigate("/hotspots");
  };

  const ProfileHandler = () => {
    navigate("/profile");
  };

  const UsersHandler = () => {
    navigate("/users");
  };

  function SignOutHandler() {
    localStorage.removeItem("AuthToken");
    navigate("/");
  }

  const mainListItems = (
    <React.Fragment>
      {/* <ListItemButton onClick={HomepageHandler}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Homepage" />
      </ListItemButton> */}
      <ListItemButton onClick={HeartBeatsHandler}>
        <ListItemIcon>
          <MonitorHeartIcon />
        </ListItemIcon>
        <ListItemText primary="Quick Status" />
      </ListItemButton>
      <Divider sx={{ my: 1 }} />
      <ListSubheader component="div" inset>
        Inventory
      </ListSubheader>
      <ListItemButton onClick={DeploymentsHandler}>
        <ListItemIcon>
          <PostAddIcon />
        </ListItemIcon>
        <ListItemText primary="Deployments" />
      </ListItemButton>
      <ListItemButton onClick={BoxesHandler}>
        <ListItemIcon>
          <HomeRepairServiceIcon />
        </ListItemIcon>
        <ListItemText primary="Boxes" />
      </ListItemButton>
      <ListItemButton onClick={BasestationHandler}>
        <ListItemIcon>
          <RouterIcon />
        </ListItemIcon>
        <ListItemText primary="Basestations" />
      </ListItemButton>
      <ListItemButton onClick={RelaysHandler}>
        <ListItemIcon>
          <SensorsIcon />
        </ListItemIcon>
        <ListItemText primary="Relays" />
      </ListItemButton>
      <ListItemButton onClick={WatchesHandler}>
        <ListItemIcon>
          <WatchIcon />
        </ListItemIcon>
        <ListItemText primary="Watches" />
      </ListItemButton>
      <ListItemButton onClick={HotspotsHandler}>
        <ListItemIcon>
          <CellTowerIcon />
        </ListItemIcon>
        <ListItemText primary="Hotspot" />
      </ListItemButton>
    </React.Fragment>
  );

  const secondaryListItems = (
    <React.Fragment>
      <ListSubheader component="div" inset>
        Account Information
      </ListSubheader>
      <ListItemButton onClick={UsersHandler}>
        <ListItemIcon>
          <PersonSearchIcon />
        </ListItemIcon>
        <ListItemText primary="Users" />
      </ListItemButton>
      <ListItemButton onClick={ProfileHandler}>
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText primary="Profile" />
      </ListItemButton>
      <ListItemButton onClick={SignOutHandler}>
        <ListItemIcon>
          <LockPersonIcon />
        </ListItemIcon>
        <ListItemText primary="Sign Out" />
      </ListItemButton>
    </React.Fragment>
  );

  return (
    <List component="nav">
      {mainListItems}
      <Divider sx={{ my: 1 }} />
      {secondaryListItems}
    </List>
  );
}
