import * as React from "react";
import { useNavigate } from "react-router-dom";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Alert from "@mui/material/Alert";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"; // Can be updated to the attached link once available: https://mui.com/x/react-date-pickers/date-time-range-picker/
import { ApplicationNavigation } from "../components/navigation";
import { AuthMiddleWare } from "../utils/authMiddleWare";

import dayjs from "dayjs";
import axios from "axios";

const drawerWidth = 300;
const defaultTheme = createTheme();

const columns = [
  { id: "deploymentTitle", label: "Name", minWidth: 170 },
  { id: "deploymentId", label: "ID", minWidth: 10 },
  {
    id: "deploymentStartDate",
    label: "Start Date",
    minWidth: 170,
  },
  {
    id: "deploymentEndDate",
    label: "End Date",
    minWidth: 170,
  },
  {
    id: "deploymentStatus",
    label: "Status",
    minWidth: 50,
  },
  {
    id: "deploymentType",
    label: "Type",
    minWidth: 150,
  },
  {
    id: "deploymentBox",
    label: "Box(es)",
    minWidth: 100,
  },
];
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(7),
      },
    }),
  },
}));

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://besic.org/">
        BESI-C
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Deployments() {
  const navigate = useNavigate();
  AuthMiddleWare(navigate);

  const [deployments, setDeployments] = React.useState({});
  const [userLevel, setUserLevel] = React.useState("");
  const [open, setOpen] = React.useState(true);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [openNewDeployment, setOpenNewDeployment] = React.useState(false);
  const [
    deploymentBoxOptionsDeepCopy,
    setDeploymentBoxOptionsDeepCopy,
  ] = React.useState([]);
  const [deploymentBoxOptions, setDeploymentBoxOptions] = React.useState([]);
  const [documentId, setDocumentId] = React.useState("");
  const [deploymentId, setDeploymentId] = React.useState("");
  const [deploymentTitle, setDeploymentTitle] = React.useState("");
  const [deploymentCreatedDate, setDeploymentCreatedDate] = React.useState("");
  const [deploymentStartDate, setDeploymentStartDate] = React.useState("");
  const [deploymentEndDate, setDeploymentEndDate] = React.useState("");
  const [deploymentStatus, setDeploymentStatus] = React.useState("");
  const [deploymentType, setDeploymentType] = React.useState("");
  const [deploymentBox, setDeploymentBox] = React.useState([]);
  const [deploymentNotes, setDeploymentNotes] = React.useState("");

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const handleOpen = () => {
    setOpenPopup(true);
  };

  const handleClose = () => {
    setDocumentId("");
    setDeploymentId("");
    setDeploymentTitle("");
    setDeploymentCreatedDate("");
    setDeploymentStartDate("");
    setDeploymentEndDate("");
    setDeploymentStatus("");
    setDeploymentType("");
    setDeploymentBox([]);
    setDeploymentNotes("");

    setDeploymentBoxOptions(deploymentBoxOptionsDeepCopy);

    setOpenPopup(false);
    setOpenNewDeployment(false);
  };

  const handleNewDeployment = () => {
    setDocumentId("");
    setDeploymentCreatedDate(new Date().toISOString());
    setOpenNewDeployment(true);
  };

  const handleOpenPopup = (event) => {
    AuthMiddleWare(navigate);
    const authToken = localStorage.getItem("AuthToken");
    axios.defaults.headers.common = { Authorization: `${authToken}` };

    let boxesInDeployment = [];

    setDeploymentBoxOptions([]);
    setDeploymentBoxOptionsDeepCopy([]);

    axios
      .get("/deployments/" + event)
      .then((response) => {
        setDocumentId(response.data.documentId);
        setDeploymentId(response.data.deploymentId);
        setDeploymentTitle(response.data.deploymentTitle);
        setDeploymentCreatedDate(response.data.deploymentCreatedDate);
        setDeploymentStartDate(response.data.deploymentStartDate);
        setDeploymentEndDate(response.data.deploymentEndDate);
        setDeploymentStatus(response.data.deploymentStatus);
        setDeploymentType(response.data.deploymentType);
        setDeploymentBox(response.data.deploymentBox);
        setDeploymentNotes(response.data.deploymentNotes);

        boxesInDeployment = response.data.deploymentBox;
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get("/boxes")
      .then((response) => {
        let boxesAvailable = [];
        response.data.map((box) => {
          boxesInDeployment.map((boxInUseTitle) => {
            if (box.boxTitle === boxInUseTitle) {
              boxesAvailable.push(box);
            }
            return boxInUseTitle;
          });
          return box;
        });

        deploymentBoxOptions.map((deploymentBox) => {
          boxesAvailable.push(deploymentBox);
          return deploymentBox;
        });
        setDeploymentBoxOptions(boxesAvailable);
      })
      .catch((error) => {
        console.log(error);
      });

    handleOpen();
  };

  React.useEffect(() => {
    AuthMiddleWare(navigate);
    const authToken = localStorage.getItem("AuthToken");
    axios.defaults.headers.common = { Authorization: `${authToken}` };

    setDeploymentBoxOptions([]);
    setDeploymentBoxOptionsDeepCopy([]);

    axios
      .get("/deployments")
      .then((response) => {
        setDeployments(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get("/boxes")
      .then((response) => {
        let boxesAvailable = [];
        response.data.map((box) => {
          if (box.boxStatus === "available") {
            boxesAvailable.push(box);
          }
          return box;
        });
        setDeploymentBoxOptions(boxesAvailable);
        setDeploymentBoxOptionsDeepCopy(boxesAvailable);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [navigate]);

  React.useEffect(() => {
    const getUserData = () => {
      AuthMiddleWare(navigate);
      const authToken = localStorage.getItem("AuthToken");
      axios.defaults.headers.common = { Authorization: `${authToken}` };

      axios
        .get("/user")
        .then((response) => {
          setUserLevel(response.data.userCredentials.level);
        })
        .catch((error) => {
          if (error.response.status === 403) {
            navigate("/signin");
          }
        });
    };

    getUserData();
  });

  function TableViewOfData() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [alert, setAlert] = React.useState("");
    const [error, setError] = React.useState("");
    const [edit, setEdit] = React.useState(false);

    const [deploymentIdToSend, setDeploymentIdToSend] = React.useState("");
    const [deploymentIdToUpdate, setDeploymentIdToUpdate] = React.useState(
      deploymentId
    );
    const [deploymentTitleToSend, setDeploymentTitleToSend] = React.useState(
      ""
    );
    const [
      deploymentTitleToUpdate,
      setDeploymentTitleToUpdate,
    ] = React.useState(deploymentTitle);
    const [
      deploymentStartDateToSend,
      setDeploymentStartDateToSend,
    ] = React.useState(new Date().toISOString());
    const [
      deploymentStartDateToUpdate,
      setDeploymentStartDateToUpdate,
    ] = React.useState(deploymentStartDate);
    const [
      deploymentEndDateToSend,
      setDeploymentEndDateToSend,
    ] = React.useState(new Date().toISOString());
    const [
      deploymentEndDateToUpdate,
      setDeploymentEndDateToUpdate,
    ] = React.useState(deploymentEndDate);
    const [deploymentStatusToSend, setDeploymentStatusToSend] = React.useState(
      ""
    );
    const [
      deploymentStatusToUpdate,
      setDeploymentStatusToUpdate,
    ] = React.useState(deploymentStatus);
    const [deploymentTypeToSend, setDeploymentTypeToSend] = React.useState("");
    const [deploymentTypeToUpdate, setDeploymentTypeToUpdate] = React.useState(
      deploymentType
    );
    const [deploymentBoxToSend, setDeploymentBoxToSend] = React.useState([]);
    const [deploymentBoxToUpdate, setDeploymentBoxToUpdate] = React.useState(
      deploymentBox
    );
    const [deploymentNotesToSend, setDeploymentNotesToSend] = React.useState(
      ""
    );
    const [
      deploymentNotesToUpdate,
      setDeploymentNotesToUpdate,
    ] = React.useState(deploymentNotes);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
    const handleEdit = () => {
      setError("");
      setAlert("");
      setEdit(!edit);
    };
    const handleDelete = () => {
      setError("");
      setAlert("");

      AuthMiddleWare(navigate);
      const authToken = localStorage.getItem("AuthToken");
      axios.defaults.headers.common = { Authorization: `${authToken}` };

      axios
        .delete("/deployments/" + documentId)
        .then((response) => {
          axios
            .get("/boxes")
            .then((response) => {
              let boxesToGoAvailable = [];

              response.data.map((box) => {
                deploymentBox.map((currentBox) => {
                  if (box.boxTitle === currentBox) {
                    boxesToGoAvailable.push(box);
                  }
                  return currentBox;
                });
                return box;
              });

              boxesToGoAvailable.map((box) => {
                const currentBox = {
                  documentId: box.id,
                  boxId: box.boxId,
                  boxTitle: box.boxTitle,
                  boxStatus: "available",
                  boxType: box.boxType,
                  boxRelays: box.boxRelays,
                  boxWatches: box.boxWatches,
                  boxHotspots: box.boxHotspots,
                  boxBasestations: box.boxBasestations,
                  boxNotes: box.boxNotes,
                };

                axios
                  .put("/boxes/" + box.documentId, currentBox)
                  .then((response) => {
                    setAlert("Success: " + response.data.message);
                  })
                  .catch((error) => {
                    setError("Error: " + error.response.data.error);
                  });

                return box;
              });
            })
            .catch((error) => {
              console.log(error);
            });

          setAlert("Success: " + response.data.message);
          window.location.reload();
        })
        .catch((error) => {
          setError("Error: " + error.response.data.error);
        });
    };

    const onDeploymentIdToSendChangeHandler = (inputText) => {
      setDeploymentIdToSend(inputText.target.value);
    };
    const onDeploymentIdToUpdateChangeHandler = (inputText) => {
      setDeploymentIdToUpdate(inputText.target.value);
    };
    const onDeploymentTitleToSendChangeHandler = (inputText) => {
      setDeploymentTitleToSend(inputText.target.value);
    };
    const onDeploymentTitleToUpdateChangeHandler = (inputText) => {
      setDeploymentTitleToUpdate(inputText.target.value);
    };
    const onDeploymentStartDateToSendChangeHandler = (inputText) => {
      setDeploymentStartDateToSend(new Date(inputText).toISOString());
    };
    const onDeploymentStartDateToUpdateChangeHandler = (inputText) => {
      setDeploymentStartDateToUpdate(new Date(inputText).toISOString());
    };
    const onDeploymentEndDateToSendChangeHandler = (inputText) => {
      setDeploymentEndDateToSend(new Date(inputText).toISOString());
    };
    const onDeploymentEndDateToUpdateChangeHandler = (inputText) => {
      setDeploymentEndDateToUpdate(new Date(inputText).toISOString());
    };
    const onDeploymentStatusToSendChangeHandler = (inputText) => {
      setDeploymentStatusToSend(inputText.target.value);
    };
    const onDeploymentStatusToUpdateChangeHandler = (inputText) => {
      setDeploymentStatusToUpdate(inputText.target.value);
    };
    const onDeploymentTypeToSendChangeHandler = (inputText) => {
      setDeploymentTypeToSend(inputText.target.value);
    };
    const onDeploymentTypeToUpdateChangeHandler = (inputText) => {
      setDeploymentTypeToUpdate(inputText.target.value);
    };
    const onDeploymentBoxToSendChangeHandler = (inputText) => {
      const {
        target: { value },
      } = inputText;
      setDeploymentBoxToSend(
        typeof value === "string" ? value.split(",") : value
      );
    };
    const onDeploymentBoxToUpdateChangeHandler = (inputText) => {
      const {
        target: { value },
      } = inputText;
      setDeploymentBoxToUpdate(
        typeof value === "string" ? value.split(",") : value
      );
    };
    const onDeploymentNotesToSendChangeHandler = (inputText) => {
      setDeploymentNotesToSend(inputText.target.value);
    };
    const onDeploymentNotesToUpdateChangeHandler = (inputText) => {
      setDeploymentNotesToUpdate(inputText.target.value);
    };

    const handleSave = () => {
      setError("");
      setAlert("");

      AuthMiddleWare(navigate);
      const authToken = localStorage.getItem("AuthToken");
      axios.defaults.headers.common = { Authorization: `${authToken}` };

      const newDeployment = {
        deploymentId: deploymentIdToSend,
        deploymentTitle: deploymentTitleToSend,
        deploymentStartDate: deploymentStartDateToSend,
        deploymentEndDate: deploymentEndDateToSend,
        deploymentStatus: deploymentStatusToSend,
        deploymentType: deploymentTypeToSend,
        deploymentBox: deploymentBoxToSend,
        deploymentNotes: deploymentNotesToSend,
      };

      axios
        .get("/boxes")
        .then((response) => {
          let boxesToGoActive = [];

          response.data.map((box) => {
            deploymentBoxToSend.map((currentBox) => {
              if (box.boxTitle === currentBox) {
                boxesToGoActive.push(box);
              }
              return currentBox;
            });
            return box;
          });

          boxesToGoActive.map((box) => {
            const currentBox = {
              documentId: box.id,
              boxId: box.boxId,
              boxTitle: box.boxTitle,
              boxStatus: "active",
              boxType: box.boxType,
              boxRelays: box.boxRelays,
              boxWatches: box.boxWatches,
              boxHotspots: box.boxHotspots,
              boxBasestations: box.boxBasestations,
              boxNotes: box.boxNotes,
            };

            axios
              .put("/boxes/" + box.documentId, currentBox)
              .then((response) => {
                setAlert("Success: " + response.data.message);
              })
              .catch((error) => {
                setError("Error: " + error.response.data.error);
              });

            return box;
          });
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .post("/deployments", newDeployment)
        .then((response) => {
          setDeploymentIdToSend("");
          setDeploymentTitleToSend("");
          setDeploymentStartDateToSend("");
          setDeploymentEndDateToSend("");
          setDeploymentStatusToSend("");
          setDeploymentTypeToSend("");
          setDeploymentBoxToSend([]);
          setDeploymentNotesToSend("");

          setAlert(
            "Deployment " + response.data.documentId + " successfully created!"
          );
          window.location.reload();
        })
        .catch((error) => {
          setError("Error: " + error.response.data.error);
        });
    };

    const handleUpdate = () => {
      AuthMiddleWare(navigate);
      const authToken = localStorage.getItem("AuthToken");
      axios.defaults.headers.common = { Authorization: `${authToken}` };

      const currentDeployment = {
        deploymentId: deploymentId,
        deploymentTitle: deploymentTitle,
        deploymentStartDate: deploymentStartDate,
        deploymentEndDate: deploymentEndDate,
        deploymentStatus: deploymentStatus,
        deploymentType: deploymentType,
        deploymentBox: deploymentBox,
        deploymentNotes: deploymentNotes,
      };

      const updateDeployment = {
        deploymentId: deploymentIdToUpdate,
        deploymentTitle: deploymentTitleToUpdate,
        deploymentStartDate: deploymentStartDateToUpdate,
        deploymentEndDate: deploymentEndDateToUpdate,
        deploymentStatus: deploymentStatusToUpdate,
        deploymentType: deploymentTypeToUpdate,
        deploymentBox: deploymentBoxToUpdate,
        deploymentNotes: deploymentNotesToUpdate,
      };

      if (
        currentDeployment.deploymentId === updateDeployment.deploymentId &&
        currentDeployment.deploymentTitle ===
          updateDeployment.deploymentTitle &&
        currentDeployment.deploymentStartDate ===
          updateDeployment.deploymentStartDate &&
        currentDeployment.deploymentEndDate ===
          updateDeployment.deploymentEndDate &&
        currentDeployment.deploymentStatus ===
          updateDeployment.deploymentStatus &&
        currentDeployment.deploymentType === updateDeployment.deploymentType &&
        currentDeployment.deploymentBox === updateDeployment.deploymentBox &&
        currentDeployment.deploymentNotes === updateDeployment.deploymentNotes
      ) {
        setError("Error: Please make a change before updating the deployment");
      } else {
        axios
          .get("/boxes")
          .then((response) => {
            let boxesToGoAvailable = [];
            let boxesToGoActive = [];

            response.data.map((box) => {
              deploymentBox.map((currentBox) => {
                if (box.boxTitle === currentBox) {
                  boxesToGoAvailable.push(box);
                }
                return currentBox;
              });

              deploymentBoxToUpdate.map((updateBox) => {
                if (box.boxTitle === updateBox) {
                  boxesToGoActive.push(box);
                }
                return updateBox;
              });

              return box;
            });

            boxesToGoAvailable.map((box) => {
              const currentBox = {
                documentId: box.id,
                boxId: box.boxId,
                boxTitle: box.boxTitle,
                boxStatus: "available",
                boxType: box.boxType,
                boxRelays: box.boxRelays,
                boxWatches: box.boxWatches,
                boxHotspots: box.boxHotspots,
                boxBasestations: box.boxBasestations,
                boxNotes: box.boxNotes,
              };

              if (!boxesToGoActive.includes(box)) {
                axios
                  .put("/boxes/" + box.documentId, currentBox)
                  .then((response) => {
                    setAlert("Success: " + response.data.message);
                  })
                  .catch((error) => {
                    setError("Error: " + error.response.data.error);
                  });
              }

              return box;
            });

            boxesToGoActive.map((box) => {
              const updateBox = {
                documentId: box.id,
                boxId: box.boxId,
                boxTitle: box.boxTitle,
                boxStatus: "active",
                boxType: box.boxType,
                boxRelays: box.boxRelays,
                boxWatches: box.boxWatches,
                boxHotspots: box.boxHotspots,
                boxBasestations: box.boxBasestations,
                boxNotes: box.boxNotes,
              };

              axios
                .put("/boxes/" + box.documentId, updateBox)
                .then((response) => {
                  setAlert("Success: " + response.data.message);
                })
                .catch((error) => {
                  setError("Error: " + error.response.data.error);
                });

              return box;
            });
          })
          .catch((error) => {
            console.log(error);
          });

        axios
          .put("/deployments/" + documentId, updateDeployment)
          .then((response) => {
            setAlert("Success: " + response.data.message);
            window.location.reload();
          })
          .catch((error) => {
            setError("Error: " + error.response.data.error);
          });
      }

      setEdit(!edit);
    };

    return (
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* eslint-disable-next-line */}
              {new Array(deployments).map((allDeployments) => {
                if (allDeployments.length !== undefined) {
                  return (
                    <React.Fragment key={Math.random()}>
                      {allDeployments
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((deployment) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={deployment.documentId}
                              onClick={() =>
                                handleOpenPopup(deployment.documentId)
                              }
                            >
                              {columns.map((column) => {
                                const value = deployment[column.id];
                                return (
                                  <TableCell key={column.id}>
                                    {value + " "}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </React.Fragment>
                  );
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[1, 5, 10, 20, 30, 50, 100]}
          component="div"
          count={deployments.length !== undefined ? deployments.length : 1}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={openPopup || openNewDeployment}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Deployment Actions
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Grid container spacing={1} align="center" justify="center">
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                margin="normal"
                paddingTop={1}
                paddingBottom={1}
                spacing={2}
              ></Grid>
              <Divider variant="middle" />
              <Grid
                container
                paddingRight={5}
                paddingLeft={5}
                paddingTop={1}
                paddingBottom={10}
                spacing={2}
              >
                <Grid
                  container
                  paddingRight={5}
                  paddingLeft={5}
                  paddingTop={3}
                  paddingBottom={10}
                  spacing={2}
                >
                  <Grid item xs={12}>
                    {alert !== "" && (
                      <Alert margin="normal" severity="success">
                        {alert}
                      </Alert>
                    )}
                    {error !== "" && (
                      <Alert margin="normal" severity="error">
                        {error}
                      </Alert>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        label="Start Date"
                        defaultValue={
                          deploymentStartDate !== ""
                            ? dayjs(deploymentStartDateToUpdate)
                            : dayjs(deploymentStartDateToSend)
                        }
                        onChange={
                          documentId !== ""
                            ? onDeploymentStartDateToUpdateChangeHandler
                            : onDeploymentStartDateToSendChangeHandler
                        }
                        disabled={!edit && !openNewDeployment}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        label="End Date"
                        defaultValue={
                          deploymentEndDate !== ""
                            ? dayjs(deploymentEndDateToUpdate)
                            : dayjs(deploymentEndDateToSend)
                        }
                        onChange={
                          documentId !== ""
                            ? onDeploymentEndDateToUpdateChangeHandler
                            : onDeploymentEndDateToSendChangeHandler
                        }
                        disabled={!edit && !openNewDeployment}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="deploymentId"
                      fullWidth
                      margin="normal"
                      id="deploymentId"
                      helperText="Deployment ID"
                      type="number"
                      label={
                        deploymentId !== ""
                          ? deploymentIdToUpdate
                          : deploymentIdToSend
                      }
                      autoFocus
                      onChange={
                        documentId !== ""
                          ? onDeploymentIdToUpdateChangeHandler
                          : onDeploymentIdToSendChangeHandler
                      }
                      disabled={!edit && !openNewDeployment}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      margin="normal"
                      id="deploymentTitle"
                      helperText="Deployment Name"
                      label={
                        deploymentTitle !== ""
                          ? deploymentTitleToUpdate
                          : deploymentTitleToSend
                      }
                      name="deploymentTitle"
                      autoFocus
                      onChange={
                        documentId !== ""
                          ? onDeploymentTitleToUpdateChangeHandler
                          : onDeploymentTitleToSendChangeHandler
                      }
                      disabled={!edit && !openNewDeployment}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="documentId"
                      fullWidth
                      margin="normal"
                      id="documentId"
                      helperText="Deployment Unique ID"
                      label={documentId}
                      autoFocus
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        label="Created Date"
                        defaultValue={dayjs(deploymentCreatedDate)}
                        disabled
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <Select
                        value={
                          deploymentType !== ""
                            ? deploymentTypeToUpdate
                            : deploymentTypeToSend
                        }
                        onChange={
                          documentId !== ""
                            ? onDeploymentTypeToUpdateChangeHandler
                            : onDeploymentTypeToSendChangeHandler
                        }
                        displayEmpty
                        disabled={!edit && !openNewDeployment}
                        inputProps={{ "aria-label": "Type" }}
                      >
                        <MenuItem value="testing">
                          <em>Testing</em>
                        </MenuItem>
                        <MenuItem value="not tested">
                          <em>Not Tested</em>
                        </MenuItem>
                        <MenuItem value="verified">
                          <em>Verified</em>
                        </MenuItem>
                      </Select>
                      <FormHelperText>Type</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <Select
                        value={
                          deploymentStatus !== ""
                            ? deploymentStatusToUpdate
                            : deploymentStatusToSend
                        }
                        onChange={
                          documentId !== ""
                            ? onDeploymentStatusToUpdateChangeHandler
                            : onDeploymentStatusToSendChangeHandler
                        }
                        displayEmpty
                        disabled={!edit && !openNewDeployment}
                        inputProps={{ "aria-label": "Status" }}
                      >
                        <MenuItem value="unknown">
                          <em>Unknown</em>
                        </MenuItem>
                        <MenuItem value="deployed">
                          <em>Deployed</em>
                        </MenuItem>
                        <MenuItem value="shipping">
                          <em>Shipping</em>
                        </MenuItem>
                        <MenuItem value="testing">
                          <em>Testing</em>
                        </MenuItem>
                        <MenuItem value="holding">
                          <em>Holding</em>
                        </MenuItem>
                        <MenuItem value="ended">
                          <em>Ended</em>
                        </MenuItem>
                      </Select>
                      <FormHelperText>Status</FormHelperText>
                    </FormControl>
                  </Grid>
                  {documentId !== "" ? (
                    <Grid item xs={12}>
                      <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <Select
                          value={deploymentBoxToUpdate}
                          onChange={onDeploymentBoxToUpdateChangeHandler}
                          displayEmpty
                          multiple
                          fullWidth
                          disabled={!edit && !openNewDeployment}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip key={value} label={value} />
                              ))}
                            </Box>
                          )}
                          inputProps={{ "aria-label": "Deployments" }}
                        >
                          {deploymentBoxOptions.map((boxItem) => (
                            <MenuItem
                              key={boxItem.boxTitle}
                              value={boxItem.boxTitle}
                            >
                              <Checkbox
                                checked={
                                  deploymentBoxToUpdate.indexOf(
                                    boxItem.boxTitle
                                  ) > -1
                                }
                              />
                              <ListItemText primary={boxItem.boxTitle} />
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>Boxes</FormHelperText>
                      </FormControl>
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <Select
                          value={deploymentBoxToSend}
                          onChange={onDeploymentBoxToSendChangeHandler}
                          displayEmpty
                          multiple
                          fullWidth
                          disabled={!edit && !openNewDeployment}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip key={value} label={value} />
                              ))}
                            </Box>
                          )}
                          inputProps={{ "aria-label": "Deployments" }}
                        >
                          {deploymentBoxOptions.map((boxItem) => (
                            <MenuItem
                              key={boxItem.boxTitle}
                              value={boxItem.boxTitle}
                            >
                              <Checkbox
                                checked={
                                  deploymentBoxToSend.indexOf(
                                    boxItem.boxTitle
                                  ) > -1
                                }
                              />
                              <ListItemText primary={boxItem.boxTitle} />
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>Boxes</FormHelperText>
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <TextField
                      name="notes"
                      fullWidth
                      margin="normal"
                      id="notes"
                      helperText="Notes"
                      label={
                        deploymentNotes !== ""
                          ? deploymentNotesToUpdate
                          : deploymentNotesToSend
                      }
                      onChange={
                        documentId !== ""
                          ? onDeploymentNotesToUpdateChangeHandler
                          : onDeploymentNotesToSendChangeHandler
                      }
                      autoFocus
                      multiline
                      disabled={!edit && !openNewDeployment}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {openNewDeployment === false && userLevel === "admin" ? (
              <Button autoFocus onClick={handleDelete}>
                Delete
              </Button>
            ) : (
              <></>
            )}

            {openNewDeployment === false &&
            (userLevel === "admin" || userLevel === "write") ? (
              edit === true ? (
                <Button autoFocus onClick={handleEdit}>
                  Preview
                </Button>
              ) : (
                <Button autoFocus onClick={handleEdit}>
                  Edit
                </Button>
              )
            ) : (
              <></>
            )}
            {documentId !== "" &&
            (userLevel === "admin" || userLevel === "write") ? (
              <Button autoFocus onClick={handleUpdate}>
                Update
              </Button>
            ) : (
              <></>
            )}
            {documentId === "" &&
            (userLevel === "admin" || userLevel === "write") ? (
              <Button autoFocus onClick={handleSave}>
                Save
              </Button>
            ) : (
              <></>
            )}
          </DialogActions>
        </BootstrapDialog>
      </Paper>
    );
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px",
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Deployments
            </Typography>
            {userLevel !== "read" ? (
              <Typography
                component="h2"
                variant="h6"
                color="inherit"
                noWrap
                onClick={handleNewDeployment}
              >
                Create Deployment
              </Typography>
            ) : (
              <></>
            )}
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <ApplicationNavigation />
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid
              container
              spacing={1}
              alignItems="center"
              justifyContent="center"
            >
              <Card align="center" justify="center">
                <Box
                  component="form"
                  noValidate
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid
                    container
                    padding={5}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <TableViewOfData />
                  </Grid>
                </Box>
              </Card>
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
